import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Typography, Box, Paper, LinearProgress, Switch, FormControlLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { resolutionColumns, compactHideAssetColumnNames } from './columns';
import axiosFundOps from '../rest-data-provider/axios';
import useTooltip from '../hooks/useTooltip';
import { stringifyError } from '../utils';

export default function Resolution() {
   
  const [isCompactView, setIsCompactView] = useState(true);

  const unpairedAssets = <UnpairedAssets isCompactView={isCompactView} />;
  const deactivatedAssets = <DeactivatedAssets isCompactView={isCompactView} />;

  return (
    <Box className='resolutions'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Resolutions</Typography>
        <FormControlLabel 
          control={
            <Switch 
              checked={isCompactView}
              onChange={(event) => setIsCompactView(event.target.checked)}
            />
          } 
          label='Compact View'
        />
      </Box>
      <Accordion sx= {{ m:1 }} defaultExpanded>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
          <Typography variant='h6' sx={{ p:1 }}>Unpaired Assets</Typography>
        </AccordionSummary>
        <Box sx={{ mt: 1 }} >
          {unpairedAssets}
        </Box>
      </Accordion>
      <Accordion sx= {{ m:1 }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant='h6' sx={{ p:1 }}>Deactivated Assets</Typography>
        </AccordionSummary>
        <Paper sx={{ mt: 1}} >
          {deactivatedAssets}
        </Paper>
      </Accordion>
    </Box>
  )
}

function UnpairedAssets({ isCompactView }) {
  const navigate = useNavigate();

  const { isLoading, isError, data: unpairedAssetIds, error } = useQuery([`unpaired-assets`], async () => {
    const { data } = await axiosFundOps.get('/resolution/assets/unpaired');
    return data;
  }, { refetchOnWindowFocus: true });

  const handleClick = (id) => {
    navigate(`/resolutions/unpaired/${id}`)
  };

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(unpairedAssetIds);

  const columns = [...resolutionColumns];
  if (isCompactView) {
    columns.forEach((column) => {
      if (compactHideAssetColumnNames.indexOf(column.field) > -1) {
        column.hide = true;
      }
      column.flex = 1;
    }); 
  } else columns.forEach((column) => {
    column.hide = false;
    column.flex = false;
  });

  const table = isError ?
    stringifyError(error) : (
    <DataGrid 
      columns={columns}
      rows={unpairedAssetIds ? unpairedAssetIds : []}
      autoHeight={true}
      loading={isLoading}
      density='compact'
      onRowClick={(params) => {handleClick(params.id)}}
      hideFooter
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      sx={{mt:1}}
    />
  )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
}

function DeactivatedAssets({ isCompactView }) {  
  const { isLoading, isError, data: deactivatedAssets, error } = useQuery([`assets-deactivated`], async () => {
    const { data } = await axiosFundOps.get('/assets', {params: {deactivated: true}});
    return data;
  }, { refetchOnWindowFocus: true });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(deactivatedAssets);

  const columns = [...resolutionColumns];
  if (isCompactView) {
    columns.forEach((column) => {
      if (compactHideAssetColumnNames.indexOf(column.field) > -1) {
        column.hide = true;
      }
      column.flex = 1;
    }); 
  } else columns.forEach((column) => {
    column.hide = false;
    column.flex = false;
  });

  const table = isError ?
    stringifyError(error) : (
      <DataGrid 
        columns={columns}
        rows={deactivatedAssets ? deactivatedAssets : []}
        autoHeight={true}
        hideFooter
        loading={isLoading}
        density='compact'
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
        sx={{ mt:1 }}
      />
    )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
}