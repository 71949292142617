import { useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { 
  Box, 
  Button,
  Typography,
  Tabs,
  Tab,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  LinearProgress,
  FormControlLabel,
  Switch,
  Checkbox,
  lighten,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axiosFundOps from '../rest-data-provider/axios';
import { delay, stringifyError } from '../utils';
import { DataGrid } from "@mui/x-data-grid";
import useTooltip from "../hooks/useTooltip";
import config from "../config";
import { isObsoleted } from "../utils/obsoletedCheck";
import { useSnackbar } from "notistack";
import AlertDialogYesNo from "../components/alertDialogYesNo";

export default function Oracles(){
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='oracles'>
      <Box sx={{ display:'flex'}}>
        <Typography variant='h4'>Oracles</Typography>
      </Box>
      <Tabs value={value} onChange={handleTabChange} sx={{ mb:2, borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="Assets" />
        <Tab label="Wallets" />
      </Tabs>
      <Box>
        {value === 0 ? <OracleAssets /> : <></>}
        {value === 1 ? <OracleWallets /> : <></>}
      </Box>
    </Box>
  )
}

function OracleAssets() {
  const [isCompactView, setIsCompactView] = useState(true);
  const [filteredChains, setFilteredChains] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilteredChains(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const { data: chainsData } = useQuery({
    queryKey: ['chains'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/chains');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const { isLoading, data:assetsData } = useQuery({
    queryKey: ['assets'], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/oracle/assets');
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  
  const { isLoading: isUnpairedLoading, data: unpairedAssetData } = useQuery({
    queryKey:[`unpaired-assets`],
    queryFn: async () => {
    const { data } = await axiosFundOps.get('/resolution/assets/unpaired');
    return data;
    },
    refetchOnWindowFocus: false
  });

  const filterByChainElement = (
    <FormControl size="small" sx={{ width: 300 }}>
      <InputLabel>Filter By Chains</InputLabel>
      <Select
        label='Filter By Chains'
        multiple
        size="small"
        fullWidth
        value={filteredChains}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {
          chainsData?.map((chain) => {
            return (
            <MenuItem 
              value={chain.name}
              key={chain.name}
              dense
              disableGutters
            >
              <Checkbox checked={filteredChains.indexOf(chain.name) > -1} />
              {chain.name}
            </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  );

  const availableAssetTable = <AssetTable 
    data={filteredChains.length > 0 ? assetsData?.filter((asset) => filteredChains.includes(asset.asset_chain)) : assetsData}
    isLoading={isLoading}
    isCompactView={isCompactView}
  />

  const unpairedAssetTable = <AssetTable 
    data={filteredChains.length > 0 ? unpairedAssetData?.filter((asset) => filteredChains.includes(asset.asset_chain)) : unpairedAssetData}
    isLoading={isUnpairedLoading}
    isCompactView={isCompactView}
  />

  return (
    <>
      <Box sx={{ display:'flex', alignContent: 'center', justifyContent:'space-between', mb:2}}>
        {filterByChainElement}
        <FormControlLabel
          control={
            <Switch
              checked={isCompactView}
              onChange={(event) => setIsCompactView(event.target.checked)}
            />
          } 
          label='Compact View'
        />
      </Box>
      <Box>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant='h6'>Available Assets</Typography>
          </AccordionSummary>
          {availableAssetTable}
        </Accordion>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant='h6'>Unpaired Assets</Typography>
          </AccordionSummary>
            {unpairedAssetTable}
        </Accordion>
      </Box>
    </>
  )
}

function AssetTable({ data, isLoading, isCompactView }) {
  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);
  const compactHideAssetColumnNames = [
    'wallet_address',
    'asset_address',
    'underlying_protocol',
    'underlying_type',
    'underlying_address',
    'units_reference_datetime',
    'price_reference_datetime',
    'apr'
  ];

  const columns = [
    { 
      field: 'asset_chain', 
      headerName: 'Chain',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'strategy_protocol',
      headerName: 'Strategy Protocol',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'strategy_name',
      headerName: 'Strategy Name',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'strategy_attribute',
      headerName: 'Strategy Attribute',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'strategy_type',
      headerName: 'Strategy Type',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'asset_protocol',
      headerName: 'Asset Protocol',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'asset_name',
      headerName: 'Asset Name',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'asset_type',
      headerName: 'Asset Type',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'asset_address',
      headerName: 'Asset Address',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'underlying_protocol',
      headerName: 'Underlying Protocol',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'underlying_name',
      headerName: 'Underlying Name',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'underlying_type',
      headerName: 'Underlying Type',
      minWidth: 120,
      width: 200,
      flex: 1
    },
    {
      field: 'underlying_address',
      headerName: 'Underlying Address',
      minWidth: 120,
      width: 200,
      flex: 1
    }
  ];

  if (isCompactView) {
    columns.forEach((column) => {
      if (compactHideAssetColumnNames.indexOf(column.field) > -1) {
        column.hide = true;
      }
      column.flex = 1;
    }); 
  } else columns.forEach((column) => {
    column.hide = false;
    column.flex = false;
  });
  
  return (
    <>
      <DataGrid 
        columns={columns}
        rows={data ? data : []}
        autoHeight={true}
        loading={isLoading}
        density='compact'
        hideFooter
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />
      {popperElement}
    </>
  )
}

function OracleWallets() {
  const nowTime = new Date()
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const columns = [
    { 
      field: 'fund_code', 
      headerName: 'Fund',
      flex: 0.5,
      type: 'singleSelect'
    },
    { 
      field: 'wallet_chain', 
      headerName: 'Chain',
      flex: 1
    },
    { 
      field: 'wallet_name', 
      headerName: 'Wallet Name',
      flex: 1
    },
    { 
      field: 'wallet_address', 
      headerName: 'Wallet Address',
      flex: 2
    },
    { 
      field: 'position_count', 
      headerName: 'Position Count',
      flex: 1
    },
    { 
      field: 'obsoleted_position_count', 
      headerName: 'Obsoleted Position Count',
      flex: 1
    },
  ]
  const [filteredFunds, setFilteredFunds] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilteredFunds(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  async function handleForceUpdate() {
    try {
      setIsSubmitAwaiting(true);
      await axiosFundOps.post(`/oracle/force_update_positions`, selectedRowIds);
      enqueueSnackbar('Wallets are updating', { variant: 'success' })
      await delay(5000 * selectedRowIds.length);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const { data: fundsData } = useQuery({
    queryKey: ['funds'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/funds');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
  const { refetch, isLoading, data: walletsData } = useQuery({
    queryKey: ['wallets'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/wallets');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) =>{
      data.forEach((wallet) => {
        wallet.obsoleted_position_count = 0;
        wallet.position_count = 0;
      })
    }
  });
  const { refetch: positionRefetch, data: oraclePositionsData } = useQuery({
    queryKey: ['oracle'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/oracle/positions');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      data.forEach((position) => {
        walletsData?.filter(
          (wallet) => (wallet.wallet_chain === position.wallet_chain && wallet.wallet_address === position.wallet_address)
        )?.forEach(
          (wallet) => {
            wallet.position_count += 1
            const isUnitsReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(position.units_reference_datetime), config.MaxReferenceDeltaMinute)
            const isPriceAssetToPlatformReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(position.price_asset_to_platform_reference_datetime), config.MaxReferenceDeltaMinute)
            const isPricePlatformToDenominationReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(position.price_platform_to_denomination_reference_datetime), config.MaxReferenceDeltaMinute)
            if (
              isUnitsReferenceDateTimeObsoleted
              || isPriceAssetToPlatformReferenceDateTimeObsoleted
              || isPricePlatformToDenominationReferenceDateTimeObsoleted
            ) {
              wallet.obsoleted_position_count += 1
            }
          }
        )
      })
    }
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(walletsData);

  const filterByFundElement = (
    <FormControl size="small" sx={{ width: 300 }}>
      <InputLabel>Filter By Funds</InputLabel>
      <Select
        label='Filter By Funds'
        multiple
        size="small"
        fullWidth
        value={filteredFunds}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {
          fundsData?.map((fund) => {
            return (
              <MenuItem 
                value={fund.code}
                key={fund.code}
                dense
                disableGutters
              >
                <Checkbox checked={filteredFunds.indexOf(fund.code) > -1} />
                {fund.code}
              </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  );
  const walletsTable = (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={walletsData ? (
          filteredFunds.length > 0 ? 
          walletsData.filter((wallet) => filteredFunds.includes(wallet.fund_code)) :
          walletsData
        ) :
          []
        }
        checkboxSelection
        density='compact'
        autoHeight
        hideFooter
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRowIds(newSelectionModel);
        }}
        getRowClassName={(params) => {
          let className = '';
          if ( params.row.obsoleted_position_count > 0 ) {
            return 'obsoleted ';
          }
          return className;
        }}
      />
      {popperElement}
    </>
  );
  const actionButtons = (
    <>
      <AlertDialogYesNo
        open={open}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleForceUpdate}
        dialogTitle={`Are you sure you want to force update the ${selectedRowIds.length} selected wallets?`}
      />
      <Button 
        variant='outlined'
        disabled={isSubmitAwaiting}
        sx={{ mr:1 }}
        onClick={
          async () => {
            await refetch()
            await positionRefetch()
          }
        }
      >
        refresh
      </Button>
      <Button
        variant='contained'
        color='warning'
        onClick={() => setOpen('submit')}
        disableElevation
        disabled={isSubmitAwaiting}
      >
        force update
      </Button>
    </>
  )

  return (
    <Box>
      <Box sx={{ display:'flex', alignContent: 'center', justifyContent:'space-between', mb:2}}>
        {filterByFundElement}
        <Box>
          {actionButtons}
        </Box>
      </Box>
      <Box sx={{
        '& .obsoleted': {
          bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9)
        },
      }}>
          {walletsTable}
      </Box>
    </Box>
  )
}