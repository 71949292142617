import { LinearProgress, Link, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, NavLink } from "react-router-dom";

export function MyVerticalTable(jsonData) {

  const verticalColumnDefinition = [
    {
      field: 'field',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Typography 
            sx={{ 
              alignSelf:'start',
              py:0.6
            }}
            noWrap
          >
            {params.value}
          </Typography>
        )
      }
    },
    {
      field: 'value',
      minWidth: 120,
      width: 200,
      flex: 5,
      renderCell: (params) => {
        const showValue = Array.isArray(params.value) ? params.value.join(', ') : params.value;
        return (
          <TextField
            value={showValue}
            multiline
            variant="standard"
            disabled
            fullWidth
            InputProps={{
              disableUnderline: true
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
              verticalAlign: "top",
              py: 0.2
              }
            }}
          />
        )
      }
    }
  ];
  
  const { details, ...fields} = {...jsonData};
  const prefixDetails = !!details ? Object.keys(details).reduce((a, c) => (a[`details.${c}`] = details[c], a), {}) : {};
  const transformedData = {...fields, ...prefixDetails};

  const keyValueData = Object.entries(transformedData).map(([key, value]) => ({id: key, field: key, value: value}));

  return (
    <DataGrid 
      columns={verticalColumnDefinition}
      rows={keyValueData}
      autoHeight
      getRowHeight={() => 'auto'}
      disableSelectionOnClick
      headerHeight={0}
      density='comfortable'
      components={{
        LoadingOverlay: LinearProgress,
      }}
      hideFooter
      sx={{
        '& .MuiDataGrid-row:hover': {
          bgcolor: 'transparent',
        },
      }}
    />
  )  
};

export function VerticalTableIssuace(data) {

  const verticalColumnDefinition = [
    {
      field: 'field',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Typography 
            sx={{ 
              alignSelf:'start',
              py:0.6
            }}
          >
            {params.row.field}
          </Typography>
        )
      }
    },
    {
      field: 'value',
      minWidth: 120,
      width: 200,
      flex: 5,
      renderCell: (params) => {
        const showValue = params.value;
        const navigatetoPath = (
          params.row.field === 'investor_full_name' ? `/investors/${data.investor_id}/`  :
          params.row.field === 'investor_account_code' ? `/investors/${data.investor_id}/accounts/${data.investor_account_code}` :
          params.row.field === 'investor_order_id' ? `/orders/${data.investor_order_id}` : ''
        )
        return (
            ['investor_full_name', 'investor_account_code', 'investor_order_id'].includes(params.row.field) ?
            <NavLink to={navigatetoPath} style={{ 'color': 'blue'  }}>{params.value}</NavLink> :
            <TextField
              value={showValue}
              multiline
              variant="standard"
              disabled
              fullWidth
              InputProps={{
                disableUnderline: true
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                verticalAlign: "top",
                py: 0.2
                }
              }}
            />
        )
      }
    }
  ];

  const keyValueData = Object.entries(data).map(([key, value]) => ({id: key, field: key, value: value}));

  return (
    <DataGrid 
      columns={verticalColumnDefinition}
      rows={keyValueData}
      autoHeight
      getRowHeight={() => 'auto'}
      disableSelectionOnClick
      headerHeight={0}
      density='compact'
      components={{
        LoadingOverlay: LinearProgress,
      }}
      hideFooter
      sx={{
        '& .MuiDataGrid-row:hover': {
          bgcolor: 'transparent',
        },
      }}
    />
  )  
};

export function VerticalTableTransactions(data) {
  const navigate = useNavigate();
  const verticalColumnDefinition = [
    {
      field: 'field',
      minWidth: 200,
      flex: 2,
      renderCell: (params) => {
        return (
          <Typography 
            sx={{ 
              alignSelf:'start',
              py:0.6
            }}
          >
            {params.row.field}
          </Typography>
        )
      }
    },
    {
      field: 'value',
      minWidth: 120,
      width: 200,
      flex: 5,
      renderCell: (params) => {
        const showValue = params.value;
        const navigatetoPath = params.row.field === 'id' ? `/transactions/${data.id}/` : params.row.field === 'details.url' ? params.value : '';
        return (
          ['id', 'details.url'].includes(params.row.field) ? (
            <Typography 
              component={Link}
              onClick={params.row.field === 'id' ? (e) => navigate(navigatetoPath) : (e) => {}} 
              href={params.row.field === 'details.url' ? navigatetoPath : ''}
              sx={{ py:0.2 }}
            >
              {showValue}
            </Typography>
          ) : (
            <TextField
              value={showValue}
              multiline
              variant="standard"
              disabled
              fullWidth
              InputProps={{
                disableUnderline: true
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                verticalAlign: "top",
                py: 0.2
                }
              }}
            />
          )
        )
      }
    }
  ];

  const { details, ...fields} = {...data};
  const prefixDetails = details !== undefined ? Object.keys(details).reduce((a, c) => (a[`details.${c}`] = details[c], a), {}) : {};
  const transformedData = {...fields, ...prefixDetails};
  const keyValueData = Object.entries(transformedData).map(([key, value]) => ({id: key, field: key, value: value}));

  return (
    <DataGrid 
      columns={verticalColumnDefinition}
      rows={keyValueData}
      autoHeight
      getRowHeight={() => 'auto'}
      disableSelectionOnClick
      headerHeight={0}
      density='compact'
      components={{
        LoadingOverlay: LinearProgress,
      }}
      hideFooter
      sx={{
        '& .MuiDataGrid-row:hover': {
          bgcolor: 'transparent',
        },
      }}
    />
  )  
};