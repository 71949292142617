import { Typography, Paper, Popper } from '@mui/material';
import { useState } from 'react';

export default function useTooltip(data) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [cellValue, setCellValue] = useState([]);

  function handlePopperOpen(event) {
      const field = event?.currentTarget?.dataset?.field;
      const id = event?.currentTarget?.parentElement?.dataset?.id;
      const row = data.find((r) => r.id === (parseInt(id) ? parseInt(id) : id));
      const showValue = row?.[field];
  
      setCellValue(showValue);
      setAnchorEl(event?.currentTarget);
    };
  
  function handlePopperClose() {
    setAnchorEl(null);
  };
  
  const popperElement = (
    <Popper
      sx={{
        pointerEvents: 'none',
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handlePopperClose}
    >
      <Paper>
          <Typography sx={{ px: '4px' }} noWrap>
            {cellValue}
          </Typography>
      </Paper>
    </Popper>
  );

  return {handlePopperOpen, handlePopperClose, popperElement};
}