import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { 
  Avatar, 
  Button, 
  TextField,
  Box,
  Typography,
  Container,
  Link
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { stringifyError } from '../utils';

import { authProvider } from '../authProvider';


export default function LogIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const from = location.state?.from?.pathname || '/';

  async function handleSubmit(event){
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      await authProvider.login({ 
        username: data.get('email'),
        password: data.get('password')
      })
      enqueueSnackbar(`Login as: ${data.get('email')}`, { variant: 'success' })
      navigate(from, { replace: true });
    } catch (error) {
      enqueueSnackbar(stringifyError(error), { variant: 'error' })
    }

  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}