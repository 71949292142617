// https://stackoverflow.com/questions/51794553/how-do-i-create-configuration-for-axios-for-default-request-headers-in-every-htt
import axios from 'axios';
import Swal from "sweetalert2";

const axiosFundOps = axios.create({
    baseURL: process.env.REACT_APP_FUNDOPS_URL,
    responseType: "json"
})

axiosFundOps.interceptors.request.use((request) => {
  const token = localStorage.getItem('access_token');
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return request;
});

axiosFundOps.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorMessage = error.response?.data?.detail ?? error.response?.statusText
    const customError = {
      ...error,
      message: typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage,
      statusCode: error.response?.status,
    };
    if (
      error.response?.status === 401
    ) {
      if (errorMessage === 'Token has expired') {
        Swal.fire({
          icon: 'error',
          title: 'Session has expired',
          text: 'Please login again',
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('username');
            window.location.href = '/login';
          }
        });
      }
      else if ( window.location.pathname !== '/login') {
        localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        window.location.href = '/login';
      }
      
    }
    return Promise.reject(customError);
  },
);

export default axiosFundOps;