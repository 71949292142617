import { useState } from 'react';
import { 
  Box, 
  Radio
 } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useTooltip from '../hooks/useTooltip';


export function SingleSelectTable(data, columns, selectedId, setSelectedId) {
  const [isExpand, setIsExpand] = useState(true);

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  function handleRowClick(e) {
    setIsExpand(!isExpand);
    setSelectedId(e.id);
  }

  const newColumns = [{
    field: 'bypass_group_id',
    headerName: ' ',
    width: 40,
    renderCell: (cellValues) => {
      return (
        <Radio 
          checked={cellValues.id === selectedId}
          onChange={() => setSelectedId(cellValues.id)}
          value={cellValues.id}
          name="radio-buttons"
        />
       )
    }
  }, ...columns ];

  const table = data ? <DataGrid 
  columns={newColumns}
  rows={isExpand ? data : data.filter((row) => row.id === selectedId)}
    pagination
    autoHeight={!isExpand}
    rowsPerPageOptions={[100]}
    pageSize={100}
    hideFooter
    density='compact'
    onRowClick={handleRowClick}
    componentsProps={{
      cell: {
        onMouseEnter: handlePopperOpen,
        onMouseLeave: handlePopperClose,
      },
    }}
  /> : <></>


  return (
    <Box sx={{ height:isExpand ? '300px' : 'auto' }}>
      {table}
      {popperElement}
    </Box>
  )
}