import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

// https://github.com/TanStack/query/issues/1657
const queryClient = new QueryClient({
  defaultOptions: {
     queries: {
        structuralSharing: false
     }
  }
});

// https://mui.com/material-ui/customization/theming/#createtheme-options-args-theme
const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto", "Helvetica", "sans-serif"
    ].join(','),
    body1: {
      fontSize: '0.875rem',
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>  
      </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
