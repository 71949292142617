import { Routes, Route } from 'react-router-dom';

import OutstandingNavs, { OutstandingNavsTimeSeriesChart } from './pages/OutstandingNavs';
import { CurrentOutstandingPositions } from './pages/Positions/CurrentPositions';
import { HistoricalOutstandingPositions } from './pages/Positions/HistoricalPositions';
import Layout from './pages/Layout';
import PrivateRoutes from './pages/PrivateRoutes';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Unpaired from './pages/Unpaired';
import Resolution from './pages/Resolution';
import Investors, { InvestorCreate, InvestorDetails, InvestorAccountCreate, InvestorAccount } from './pages/Investors';
import Orders, { OrderCreate, Order, OrderStatusCreate, OrderIssuanceCreate, OrderTransactionCreate } from './pages/Orders';
import Transactions, { Transaction, TransactionCreate } from './pages/Transactions';
import Funds, { FundDetail, FundCreate, ManagementFeeCreate, PerformanceFeeCreate, FundWalletCreate } from './pages/Funds';
import Chains, { ChainCreate } from './pages/Chains';
import Custodians, { CustodialAccountCreate, CustodialAccountDetail, CustodianCreate, CustodianDetail } from './pages/Custodians';
import Users, { UserCreate } from './pages/Users';
import Oracles from './pages/Oracles';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<Home />} index />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/oracles/" element={<Oracles />} />
          <Route path="/users/" element={<Users />} />
          <Route path="/users/create" element={<UserCreate />} />
          <Route path="/chains/" element={<Chains />} />
          <Route path="/chains/create" element={<ChainCreate />} />
          <Route path="/custodians/" element={<Custodians />} />
          <Route path="/custodians/create" element={<CustodianCreate />} />
          <Route path="/custodians/:custodianCode/" element={<CustodianDetail />} />
          <Route path="/custodians/:custodianCode/custodial_accounts/create" element={<CustodialAccountCreate />} />
          <Route path="/custodians/:custodianCode/custodial_accounts/:custodialAccountCode" element={<CustodialAccountDetail />} />
          <Route path="/funds/" element={<Funds />} />
          <Route path="/funds/create/" element={<FundCreate />} />
          <Route path="/funds/:fundCode/" element={<FundDetail />} />
          <Route path="/funds/:fundCode/wallet/create" element={<FundWalletCreate />} />
          <Route path="/funds/:fundCode/portfolios/" element={<OutstandingNavs />} >
            <Route index element={<OutstandingNavsTimeSeriesChart />} />
            <Route path="current_position/" element={<CurrentOutstandingPositions />} />
            <Route path=":positionDateTime/" element={<HistoricalOutstandingPositions />} />
          </Route>
          <Route path="/funds/:fundCode/management_fee/create/" element={<ManagementFeeCreate />} />
          <Route path="/funds/:fundCode/performance_fee/create/" element={<PerformanceFeeCreate />} />
          <Route path="/resolutions/" element={<Resolution />} />
          <Route path="/resolutions/unpaired/:unpairedAssetId" element={<Unpaired />} />
          <Route path="/investors/" element={<Investors />} />
          <Route path="/investors/create" element={<InvestorCreate />} />
          <Route path="/investors/:investorId/" element={<InvestorDetails />} />
          <Route path="/investors/:investorId/accounts/create/" element={<InvestorAccountCreate />} />
          <Route path="/investors/:investorId/accounts/:investorAccountCode/" element={<InvestorAccount />} />
          <Route path="/orders/" element={<Orders />} />
          <Route path="/orders/create/" element={<OrderCreate />} />
          <Route path="/orders/:orderId/" element={<Order />}>
            <Route path="/orders/:orderId/create/status" element={<OrderStatusCreate />} />
            <Route path="/orders/:orderId/create/issuance" element={<OrderIssuanceCreate />} />
            <Route path="/orders/:orderId/create/transaction" element={<OrderTransactionCreate />} />
          </Route>
          <Route path="/transactions/" element={<Transactions />} />
          <Route path="/transactions/:transactionId" element={<Transaction />} />
          <Route path="/transactions/create/" element={<TransactionCreate />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
