import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { 
  Typography, 
  Box, 
  LinearProgress, 
  Button, 
  TextField,
  Grid, 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { lighten } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import AlertDialogYesNo from '../components/alertDialogYesNo';

import { 
  stringifyError, 
  delay, 
} from '../utils';
import { MyBreadcrumbs } from '../components/breadcrumbs';

export default function Chains() {
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`chains`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/chains');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const chainsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 5
    },
    { 
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    { 
      field: 'address',
      headerName: 'Address',
      flex: 1,
    }
  ]

  const table = isError ?
    stringifyError(error) :
    <DataGrid 
      columns={chainsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      disableSelectionOnClick
      loading={isLoading}
      density='compact'
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
    />

  return (
    <Box className='chains'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Chains</Typography>
        <Button
          onClick={() => navigate('/chains/create/')}
          startIcon={<AddIcon />}
          size='small'
          disableElevation
        >
          Create Chain
        </Button>
      </Box>
        <Box
          sx={{ 
            flex: '1 1 auto',
            overflow: 'hidden',
            '& .unupdate': {
              bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9),
            }
          }}
        >
          {table}
          {popperElement}
        </Box>
    </Box>
  )
};

export function ChainCreate() {
  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
      name: "",
      address: null
  });

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const response = await axiosFundOps.post(`/chains/create`, formValues);
      enqueueSnackbar(`Chains ${response.data.name} created. Redirect to chains page`, { variant: 'success' })
      await delay(1000);
      navigate(`/chains/`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const formInput = (
    <>
      <Box>
        <Box sx={{ mb:2 }}>
          <TextField
            name="name"
            label="Name"
            value={formValues.name}
            size="small"
            fullWidth
            required
            onChange={handleInputChange}
          />
        </Box>
        <Box>
          <TextField
            name="address"
            label="Address"
            value={formValues.address}
            size="small"
            fullWidth
            onChange={handleInputChange}
          />
        </Box>
      </Box>
    </>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a new chain from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='create-chain'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}