import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { 
  Box, 
  Paper, 
  Typography,
  Button,
  Radio,
  LinearProgress,
  FormControlLabel,
  useRadioGroup,
  Switch
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { lighten } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import PowerOffIcon from '@mui/icons-material/PowerOff';

import AlertDialogYesNo from '../components/alertDialogYesNo';
import { unpairedColumns, compactHideAssetColumnNames } from './columns';
import useTooltip from '../hooks/useTooltip';
import { delay, stringifyError } from '../utils';
import axiosFundOps from '../rest-data-provider/axios';

export default function Unpaired() {
   
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [openConvert, setOpenConvert] = useState(false);
  const [radioChecked, setRadioChecked] = useState(null);
  const [isDeactivateAwaiting, setIsDeactivateAwaiting] = useState(false);
  const [isCompactView, setIsCompactView] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { unpairedAssetId } = useParams();

  const unpairedAssetDetail = <UnpairedAssetDetail unpairedAssetId={unpairedAssetId} radioChecked={radioChecked} isCompactView={isCompactView} />
  const oracleAssetTable = <OracleAsset radioChecked={radioChecked} setRadioChecked={setRadioChecked} isCompactView={isCompactView}  />

  function handleResolve() {
    if (radioChecked === 'deactivate') {
      setOpenDeactivate(true);
    } else {
      setOpenConvert(true);
    }
  }

  async function handleConvert() {    
    try {
      setIsDeactivateAwaiting(true);
      await axiosFundOps.post(`/resolution/assets/unpaired/${unpairedAssetId}`, {'oracle_asset_id': radioChecked.id, 'active': true});
      enqueueSnackbar('Asset converted, navigating to "/resolutions"', { variant: 'success' })
      await delay(1000);
      navigate('/resolutions');
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })
    } finally {
      setIsDeactivateAwaiting(false);
    }
  };

  async function handleDeactivate() {    
    try {
      setIsDeactivateAwaiting(true);
      await axiosFundOps.post(`/resolution/assets/unpaired/${unpairedAssetId}`, {'oracle_asset_id': 0, 'active': false});
      enqueueSnackbar('Asset deactivated, navigating to "/resolutions"', { variant: 'success' })
      await delay(1000);
      navigate('/resolutions')
      
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })
    } finally {
      setIsDeactivateAwaiting(false);
    }
  };

      
  const alertDialogDeactivate = (
    <AlertDialogYesNo 
      open={openDeactivate}
      handleClose={() => setOpenDeactivate(false)}
      handleOpen={() => setOpenDeactivate(true)}
      handleYes={handleDeactivate}
      dialogTitle={`Are you sure you want to deactivate unpaired asset ${unpairedAssetId}?`}
    />
  )

  const alertDialogConvert = (
    <AlertDialogYesNo 
      open={openConvert}
      handleClose={() => setOpenConvert(false)}
      handleOpen={() => setOpenConvert(true)}
      handleYes={handleConvert}
      dialogTitle={`Are you sure you want to convert unpaired asset ${unpairedAssetId} to selected oracle asset?`}
    />
  )

  return (
    <Box className="unpaired-resolution" sx={{ display: 'flex', flexDirection: 'column', flex:1 }}>
      {alertDialogDeactivate}
      {alertDialogConvert}
      <Typography variant='h5' sx={{ mb:1 }}>Resolving Unpaired Asset</Typography>
      <Paper sx={{ p:1, mb:2 }}>
        <Button sx={{mr:1, mb:1}} 
          onClick={handleResolve} 
          disabled={isDeactivateAwaiting || !Boolean(radioChecked)}
          // variant='contained'
        >
          resolve
        </Button>
        <FormControlLabel 
          control={
            <Switch 
              checked={isCompactView}
              onChange={(event) => setIsCompactView(event.target.checked)}
            />
          } 
          label='Compact View'
          sx={{float:'right'}}
        />
        {unpairedAssetDetail}
      </Paper>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h6' sx={{ mb:1 }}>Resolution Options</Typography>
        <Paper 
          sx={{ display: 'flex', flexDirection: 'column', p:1, flex:1 }}
        >
          <MyFormControlLabel 
            value='deactivate asset'
            control={<Radio />}
            checked={radioChecked === 'deactivate'}
            onChange={() => setRadioChecked('deactivate')}
            label='deactivate asset'
            sx= {{ml: 1, mt:1}}
          />
          <Typography sx= {{ mb:1, mt: 3, fontWeight: 'bold' }}>Convert To Selected Asset</Typography>
          <Box sx={{ flex: 1}}>
            {oracleAssetTable}
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export function UnpairedAssetDetail({ unpairedAssetId, radioChecked, isCompactView }) {
  const { isLoading, isError, data: unpairedAsset, error } = useQuery([`unpaired-${unpairedAssetId}`], async () => {
    const { data } = await axiosFundOps.get(`/resolution/assets/unpaired/${unpairedAssetId}`);
    return data;
  }, { refetchOnWindowFocus: true });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(unpairedAsset);

  let SelectedOptionRow = [];
  if (radioChecked === 'deactivate') {
    SelectedOptionRow = [
      {
        id: 'deactivate',
        span: true,
        asset_chain: 'deactivate asset'
      }
    ]
  } else if (radioChecked !== null) {
    SelectedOptionRow = [radioChecked];
  } else SelectedOptionRow = [];

  let columns = [...unpairedColumns];

  if (isCompactView) {
    columns.forEach((column) => {
      if (compactHideAssetColumnNames.indexOf(column.field) > -1) {
        column.hide = true;
      }
      column.flex = 1;
    }); 
  } else columns.forEach((column) => {
    column.hide = false;
    column.flex = false;
  });

  columns = [
    {
      field: 'actions',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (cellValues) => {
        if (cellValues.id === parseInt(unpairedAssetId)) {
          return <PowerOffIcon />
        } else return<BuildIcon fontSize='small' />
      }
    },
    ...columns
  ];

  const unpairedAssetTable = isError ?
    stringifyError(error) : (
    <DataGrid 
      columns={columns}
      rows={unpairedAsset ? [unpairedAsset, ...SelectedOptionRow] : []}
      hideFooter
      autoHeight={true}
      loading={isLoading}
      density='compact'
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
    />
  )

  return (
    <Box sx={{
      '& .even': {
        bgcolor: (theme) => lighten(theme.palette.error.main, 0.9)
      },
      '& .odd': {
        bgcolor: (theme) => lighten(theme.palette.success.main, 0.9)
      },
    }}>
      {unpairedAssetTable}
      {popperElement}
    </Box>
  )
}

function OracleAsset({ radioChecked, setRadioChecked }) {
  const { isLoading, isError, data, error } = useQuery([`oracle-assets`], async () => {
    const { data } = await axiosFundOps.get('/oracle/assets');
    return data;
  }, { refetchOnWindowFocus: true });

  const oracleColumns = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Radio 
            checked={cellValues?.id === radioChecked?.id}
            onChange={() => setRadioChecked(cellValues.row)}
            value={cellValues?.id}
          />
         )
      }
    },
    ...unpairedColumns
  ]

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = isError ?
    stringifyError(error) : (
    <DataGrid
      columns={oracleColumns}
      rows={data ? data: []}
      loading={isLoading}
      density='compact'
      // autoHeight
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      onSelectionModelChange={(newSelectionModel) => {
        const row = data?.find((row) => row?.id === newSelectionModel?.[0])
        setRadioChecked(row);
      }}
    >
    </DataGrid>
  )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
}


function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <FormControlLabel checked={checked} {...props} />;
}
