import { 
  Divider,
  Box,
  Typography,
} from "@mui/material";

import { formatNumber } from "../utils/formatNumber";

export function GavFooter(props) {
  return (
    <>
      <Divider />
      <Box sx={{ p: 1.5, display: 'flex', flexDirection:'row-reverse', justifyContent:'space-between'}}>
        <Typography variant='body1' fontWeight='bold'>
          GAV: {'$' + formatNumber(props.gav)}
        </Typography>
        <Typography variant='body1' fontWeight='bold'>
          {props.numRow} rows
        </Typography>
      </Box>
    </>
  );
};

export function SumUnitsFooter(props) {
  return (
    <>
      <Divider />
      <Box sx={{ p: 1.5, display: 'flex', flexDirection:'row-reverse', justifyContent:'space-between'}}>
        <Typography variant='body1' fontWeight='bold'>
          Units: {formatNumber(props.sumUnits)}
        </Typography>
        <Typography variant='body1' fontWeight='bold'>
          {props.numRow} rows
        </Typography>
      </Box>
    </>
  );
};

export function NoRowsOverlay({errorMessage}) {
  return (
    <Box sx={{ m:1, display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
      <Box>
        { errorMessage ? errorMessage : "No Rows"}
      </Box>
    </Box>
  );
}

export function renderNumber( { value } ) {
  const number = new Intl.NumberFormat('en-US', {minimumFractionDigits: 6} ).format(value);
  return (
    <Box 
      sx={{ 
        overflow: 'hidden', 
        textOverflow: 'ellipsis'
      }}
    >
        {number}
    </Box>
  )
}