import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
  Typography,
  Box,
  LinearProgress,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Link,
  FormControlLabel,
  Switch,
  IconButton,
  Grid,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import { useSnackbar } from 'notistack';

import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import { assetsColumns, ordersColumns } from './columns';
import AlertDialogYesNo from '../components/alertDialogYesNo';
import { MyVerticalTable, VerticalTableTransactions } from '../components/verticalTable';
import { stringifyError, delay, isValidHttpUrl, toISOStringWithTimezone, } from '../utils';
import { MyBreadcrumbs } from '../components/breadcrumbs';
import { SingleSelectTable } from '../components/selectTable'

const qs = require('qs');

export default function Transactions() {
  

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isCompactView, setIsCompactView] = useState(true);
  const pageSize = 14;

  const transactionsColumns = [
    { 
      field: 'id',
      headerName: 'ID',
      type: 'number',
      width: 50,
    },
    { 
      field: 'datetime',
      type: 'dateTime',
      headerName: 'Datetime',
      width: 200,
      flex: 0,
    },
    { 
      field: 'transaction_chain_name',
      headerName: 'Chain',
      width: 200,
      flex: 0,
    },
    { 
      field: 'external_transaction_id',
      headerName: 'External TX ID',
      width: 200,
      flex: 0,
    },
    { 
      field: 'external_transaction_subid',
      headerName: 'External TX Sub ID',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_chain_name',
      headerName: 'From Chain',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_wallet_address',
      headerName: 'From Wallet Address',
      minWidth: 200,
      flex: 0,
    },
    { 
      field: 'from_wallet_name',
      headerName: 'From Wallet Name',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_wallet_portfolio_code',
      headerName: 'From Wallet Portfolio Code',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_asset_code',
      headerName: 'From Asset Code',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_asset_id',
      headerName: 'From Asset ID',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_units',
      headerName: 'From Units',
      width: 200,
      flex: 0,
    },
    { 
      field: 'from_traceability',
      headerName: 'From Traceability',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_chain_name',
      headerName: 'To Chain',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_wallet_address',
      headerName: 'To Wallet Address',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_wallet_name',
      headerName: 'To Wallet Name',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_wallet_portfolio_code',
      headerName: 'To Wallet Portfolio Code',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_asset_code',
      headerName: 'To Asset Code',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_asset_id',
      headerName: 'To Asset ID',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_units',
      headerName: 'To Units',
      width: 200,
      flex: 0,
    },
    { 
      field: 'to_traceability',
      headerName: 'To Traceability',
      width: 200,
      flex: 0,
    },
    {
      field: 'url',
      type: 'actions',
      headerName: 'URL',
      width: 70,
      getActions: (params) => {
        return [
          <IconButton
            component={Link}
            href={params.row.url}
          >
            <TravelExploreIcon />
          </IconButton>
        ];
      },
    }
  ];
  
  const transactionCompactColumns = [
    { 
      field: 'id',
      headerName: 'ID',
      type: 'number',
      flex: 0.1,
    },
    { 
      field: 'datetime',
      type: 'dateTime',
      headerName: 'Datetime',
      flex: 1,
    },
    { 
      field: 'transaction_chain_name',
      headerName: 'Chain',
      flex: 1,
    },
    { 
      field: 'external_transaction_id',
      headerName: 'External TX ID',
      flex: 1,
    },
    { 
      field: 'from_wallet_address',
      headerName: 'From Wallet Address',
      flex: 1,
    },
    { 
      field: 'to_wallet_address',
      headerName: 'To Wallet Address',
      flex: 1,
    },
    { 
      field: 'to_units',
      headerName: 'To Units',
      flex: 1,
    },
    { 
      field: 'to_asset_code',
      headerName: 'To Asset Code',
      flex: 1,
    },
    { 
      field: 'to_asset_id',
      headerName: 'To Asset ID',
      flex: 1,
    },
    {
      field: 'url',
      type: 'actions',
      headerName: 'URL',
      width: 70,
      getActions: (params) => {
        return [
          <IconButton
            component={Link}
            href={params.row.url}
          >
            <TravelExploreIcon />
          </IconButton>
        ];
      },
    }
  ];

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`transactions-${page}`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get(
        '/transactions',
        {
          params: {
            offset: page * pageSize,
            limit: pageSize
          }
        }
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  data?.forEach((row) => {
    const { details } = row;
    row.remarks = details?.remarks;
    row.url = details?.url;
  })



  const { handlePopperOpen, handlePopperClose, popperElement } = useTooltip(data);

  const table = isLoading ?
    <CircularProgress /> :
    isError ?
      stringifyError(error) :
      <DataGrid
        columns={isCompactView ? transactionCompactColumns : transactionsColumns}
        rows={data ? data : []}
        loading={isLoading}
        pageSize={pageSize}
        rowCount={100}
        onPageChange={(newPage) => setPage(newPage)}
        page={page}
        rowsPerPageOptions={[pageSize]}
        autoHeight
        pagination
        paginationMode="server"
        density='compact'
        onRowClick={(params) => { navigate(`/transactions/${params.id}`) }}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />

  return (
    <Box className='investors'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Transactions</Typography>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={isCompactView}
                onChange={(event) => setIsCompactView(event.target.checked)}
              />
            }
            label='Compact View'
          />
          <Button
            onClick={() => navigate('/transactions/create/')}
            startIcon={<AddIcon />}
            size='small'
            disableElevation
          >
            Add Transaction
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        {table}
        {popperElement}
      </Box>
    </Box>
  )
};

export function Transaction() {
  
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const { data: transactionData } = useQuery({
    queryKey: [`transaction-${transactionId}`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/transactions/${transactionId}`);
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
  const {
    data: investorOrdersData,
    isLoading: isOrderLoading,
    isError: isOrderError,
    error: orderError } = useQuery({
      queryKey: [`orders-transaction_id=${transactionId}-${page}`],
      queryFn: async () => {
        const { data } = await axiosFundOps.get(
          '/orders', {
          params: {
            transaction_id: transactionId,
            offset: page * pageSize,
            limit: pageSize
          }
        });
        return data;
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false
    });
  investorOrdersData?.forEach((order) => {
    if (order.latest_status) {
      order.latest_status_datetime = order.latest_status.datetime;
      order.latest_status_ = order.latest_status.status;
    }
  });


  const { handlePopperOpen, handlePopperClose, popperElement } = useTooltip(investorOrdersData);

  const orderTable = isOrderError ?
    stringifyError(orderError) :
    (
      <>
      <DataGrid
        columns={ordersColumns}
        rows={investorOrdersData ? investorOrdersData : []}
        loading={isOrderLoading}
        pageSize={pageSize}
        rowCount={100}
        onPageChange={(newPage) => setPage(newPage)}
        onRowClick={(params) => navigate(`/orders/${params.id}`)}
        page={page}
        rowsPerPageOptions={[pageSize]}
        autoHeight
        pagination
        paginationMode="server"
        density='compact'
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />
      {popperElement}
      </>
    )

  const splitDetailByType = Object.keys(transactionData ? transactionData : {}).reduce((result, currKey) => {
    if (currKey.startsWith('from')) {
      result['from'][currKey] = transactionData[currKey];
    }
    else if (currKey.startsWith('to')) {
      result['to'][currKey] = transactionData[currKey];
    }
    else {
      result['transaction'][currKey] = transactionData[currKey];
    }
    return result;
  }, { from: {}, to: {}, transaction: {} });

  const transactionTableDetail = (
    <>
      <Box sx={{ display: 'flex', mb: 3 }}>
        <Box sx={{ flex: 1, pr: 3 }}>
          <Typography variant='h6' sx={{ mb: 1 }}>From</Typography>
          {MyVerticalTable(splitDetailByType.from)}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant='h6' sx={{ mb: 1 }}>To</Typography>
          {MyVerticalTable(splitDetailByType.to)}
        </Box>
      </Box>
      <Box>
        <Typography variant='h6' sx={{ mb: 1 }}>Transaction</Typography>
        {VerticalTableTransactions(splitDetailByType.transaction)}
      </Box>
    </>
  );

  return (
    <Box className='transaction'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column' }}>
        {transactionTableDetail}
      </Box>
      <Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant='h6'>Relevant Orders</Typography>
        </Box>
        <Box>
          {orderTable}
        </Box>
      </Box>
    </Box>
  )
};

export function TransactionCreate() {
  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [isCrossChain, setIsCrossChain] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(false);

  const [formValues, setFormValues] = useState(
    {
      "datetime": toISOStringWithTimezone(new Date()).slice(0, -6),
      "from_chain_name": "",
      "from_wallet_address": null,
      "from_wallet": null,
      "from_asset_id": null,
      "from_external_position_id": null,
      "from_units": null,
      "from_traceability": "untraceable",
      "to_chain_name": "",
      "to_wallet_address": null,
      "to_wallet": null,
      "to_asset_id": null,
      "to_external_position_id": null,
      "to_units": null,
      "to_traceability": "untraceable",
      "transaction_chain_name": "",
      "external_transaction_id": null,
      "external_transaction_subid": null,
      "url": null,
      "remarks": null
    }
  );

  const { data: chainsData } = useQuery({
    queryKey: ['chains'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/chains');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
  const { data: assetsData } = useQuery({
    queryKey: ['assets-strategy_protocol=wallet&spot_account'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get(
        '/assets',
        {
          params: {
            strategy_protocol: ['wallet', 'spot_account']
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
        }
      );
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
  const { data: walletsData } = useQuery({
    queryKey: ['wallets'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/wallets');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  async function handleSubmit() {
    try {
      setIsSubmitAwaiting(true);
      const { url, remarks, from_wallet, to_wallet, ...payload } = formValues;
      payload.details = { url, remarks };
      payload.from_wallet_id = from_wallet ? from_wallet.id : null;
      payload.to_wallet_id = to_wallet ? to_wallet.id : null;
      const response = await axiosFundOps.post(`/transactions/create`, payload);
      enqueueSnackbar('Transaction created. Redirect to transaction detail page.', { variant: 'success' })
      await delay(500);
      navigate(`/transactions/${response.data.id}/`);
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function handleIsCrossChainCheck(event, newValue) {
    if (isCrossChain) {
      setFormValues({
        ...formValues,
        from_chain_name: formValues.transaction_chain_name,
        to_chain_name: formValues.transaction_chain_name
      })
    }
    if (newValue !== null) setIsCrossChain(newValue);
  }

  function validateUrl() {
    setIsValidUrl(isValidHttpUrl(formValues.url));
  }

  const fromAssetSelectElement = SingleSelectTable(assetsData, assetsColumns, formValues.from_asset_id, ((value) => setFormValues({ ...formValues, 'from_asset_id': value })));
  const toAssetSelectElement = SingleSelectTable(assetsData, assetsColumns, formValues.to_asset_id, ((value) => setFormValues({ ...formValues, 'to_asset_id': value })));

  const formInput = (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>Transaction</Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="datetime"
              label="Datetime"
              type='datetime-local'
              value={formValues.datetime}
              size="small"
              onChange={handleInputChange}
            />
          </Box>
          <Box>
            <FormControl size='small' sx={{ mb: 2, minWidth: '150px', mr: 2 }}>
              <InputLabel>Chain</InputLabel>
              <Select
                value={formValues.transaction_chain_name}
                name="transaction_chain_name"
                label="Chain"
                fullWidth
                onChange={(e) => {
                  if (!isCrossChain) {
                    const { value } = e.target;
                    const from_wallet = walletsData.filter((wallet) => wallet.wallet_address === formValues.from_wallet_address && wallet.wallet_chain === value).at(0);
                    const to_wallet = walletsData.filter((wallet) => wallet.wallet_address === formValues.to_wallet_address && wallet.wallet_chain === value).at(0);
                    setFormValues({
                      ...formValues,
                      transaction_chain_name: value,
                      from_chain_name: value,
                      to_chain_name: value,
                      from_wallet: from_wallet ? from_wallet : null,
                      to_wallet: to_wallet ? to_wallet : null
                    })
                  } else {
                    handleInputChange(e);
                  }
                }}
              >
                {
                  chainsData?.map((chain) => {
                    return <MenuItem value={chain.name} key={chain.name}>{chain.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <ToggleButtonGroup
              color="primary"
              value={isCrossChain}
              size="small"
              exclusive
              onChange={handleIsCrossChainCheck}
              aria-label="Platform"
            >
              <ToggleButton value={false}>Same Chain</ToggleButton>
              <ToggleButton value={true}>Cross Chain</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <TextField
              name="external_transaction_id"
              label="External TX ID"
              value={formValues.external_transaction_id}
              fullWidth
              size="small"
              onChange={handleInputChange}
              sx={{ pr: 2 }}
            />
            <TextField
              name="external_transaction_subid"
              label="External TX Sub ID"
              value={formValues.external_transaction_subid}
              fullWidth
              size="small"
              onChange={handleInputChange}
            />
          </Box>
          <TextField
            name="url"
            label="URL"
            value={formValues.url}
            fullWidth
            size="small"
            onChange={handleInputChange}
            onBlur={validateUrl}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: isValidUrl ? "success.light" : "error.light" }
              },
              mb: 2
            }}
          />
          <TextField
            name="remarks"
            label="Remarks"
            value={formValues.remarks}
            fullWidth
            size="small"
            multiline
            minRows={3}
            onChange={handleInputChange}
            sx={{ mb: 1 }}
          />

        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>From</Typography>
        <Box>
          <Box sx={{ mb: 1, display: 'flex' }}>
            <FormControl size='small' sx={{ minWidth: '150px', mr: 2 }} disabled={!isCrossChain}>
              <InputLabel>Chain</InputLabel>
              <Select
                value={formValues.from_chain_name}
                name="from_chain_name"
                label="Chain"
                fullWidth
                onChange={(e) => {
                  const { name, value } = e.target;
                  const from_wallet = walletsData.filter((wallet) => wallet.wallet_address === formValues.from_wallet_address && wallet.wallet_chain === value).at(0);
                  setFormValues({
                    ...formValues,
                    [name]: value,
                    from_wallet: from_wallet ? from_wallet : null
                  });
                }}
              >
                {
                  chainsData?.map((chain) => {
                    return <MenuItem value={chain.name} key={chain.name}>{chain.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <TextField
              name="from_wallet_address"
              label="Wallet Address"
              value={formValues.from_wallet_address}
              fullWidth
              size="small"
              onChange={(e) => {
                const { name, value } = e.target;
                const from_wallet = walletsData.filter((wallet) => wallet.wallet_address === value && wallet.wallet_chain === value).at(0);
                setFormValues({
                  ...formValues,
                  [name]: value,
                  from_wallet: from_wallet ? from_wallet : null
                });
              }}
              InputProps={{
                endAdornment: formValues.from_wallet ?
                  <>
                    <Typography color='primary'>{`${formValues.from_wallet?.fund_code}/${formValues.from_wallet?.custodial_account_name}/${formValues.from_wallet?.wallet_name}`}</Typography>
                  </>
                  : <></>,
              }}
            />
          </Box>
          <TextField
            name="from_external_position_id"
            label="External position id"
            value={formValues.from_external_position_id}
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ mb: 1, display: 'none' }}
          />
          <Box sx={{ mb: 2 }}>
            Asset:
            {fromAssetSelectElement}
          </Box>
          <TextField
            name="from_units"
            label="Units"
            value={formValues.from_units}
            type="number"
            onWheel={(e) => e.target.blur()}
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ mb: 1 }}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>To</Typography>
        <Box>
          <Box sx={{ mb: 2, display: 'flex' }}>
            <FormControl size='small' sx={{ mr: 2, minWidth: '150px' }} disabled={!isCrossChain}>
              <InputLabel>Chain</InputLabel>
              <Select
                value={formValues.to_chain_name}
                name="to_chain_name"
                label="Chain"
                fullWidth
                onChange={(e) => {
                  const { name, value } = e.target;
                  const to_wallet = walletsData.filter((wallet) => wallet.wallet_address === formValues.to_wallet_address && wallet.wallet_chain === value).at(0);
                  setFormValues({
                    ...formValues,
                    [name]: value,
                    to_wallet: to_wallet ? to_wallet : null
                  });
                }}
              >
                {
                  chainsData?.map((chain) => {
                    return <MenuItem value={chain.name} key={chain.name}>{chain.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            <TextField
              name="to_wallet_address"
              label="Wallet Address"
              value={formValues.to_wallet_address}
              fullWidth
              size="small"
              onChange={(e) => {
                const { name, value } = e.target;
                const to_wallet = walletsData.filter((wallet) => wallet.wallet_address === value && wallet.wallet_chain === value).at(0);
                setFormValues({
                  ...formValues,
                  [name]: value,
                  to_wallet: to_wallet ? to_wallet : null
                });
              }}
              sx={{ mb: 1 }}
              InputProps={{
                endAdornment: formValues.to_wallet ?
                  <>
                    <Typography color='primary'>{`${formValues.to_wallet?.fund_code}/${formValues.to_wallet?.custodial_account_name}/${formValues.to_wallet?.wallet_name}`}</Typography>
                  </>
                  : <></>,
              }}
            />
          </Box>
          <TextField
            name="to_external_position_id"
            label="External position id"
            value={formValues.to_external_position_id}
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ mb: 1, display: 'none' }}
          />
          <Box sx={{ mb: 2 }}>
            Asset:
            {toAssetSelectElement}
          </Box>
          <TextField
            name="to_units"
            label="Units"
            value={formValues.to_units}
            type="number"
            onWheel={(e) => e.target.blur()}
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ mb: 1 }}
          />
        </Box>

      </Box>
    </>
  );

  const buttons = (
    <>
      <AlertDialogYesNo
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a transaction from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  )

  return (
    <Box className='transactions'>
      <Box  sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};