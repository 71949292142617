export function stringifyError(error) {
  let errorDetail = '';
  if (error.code === 'ERR_NETWORK') {
    return 'Error 500 Internal Server Error: Please contact admin'
  } else if (typeof error.response.data.detail === 'string') {
    errorDetail = error.response.data.detail;
  } else if (Array.isArray(error.response.data.detail)) {
    errorDetail = error.response.data.detail.map((error_i) => `${error_i.msg} (${error_i.loc})`)
  } 
  return `Error ${error.response.status} ${error.response.statusText}: ${errorDetail}.`
}