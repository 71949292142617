import { Typography } from '@mui/material';

const compactHideAssetColumnNames = [
  'wallet_address',
  'asset_address',
  'underlying_protocol',
  'underlying_type',
  'underlying_address',
  'units_reference_datetime',
  'price_reference_datetime',
  'apr'
];

const resolutionColumns = [
  { 
    field: 'asset_chain', 
    headerName: 'Chain',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'strategy_protocol',
    headerName: 'Strategy Protocol',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'strategy_name',
    headerName: 'Strategy Name',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'strategy_attribute',
    headerName: 'Strategy Attribute',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'strategy_type',
    headerName: 'Strategy Type',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'asset_protocol',
    headerName: 'Asset Protocol',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'asset_name',
    headerName: 'Asset Name',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'asset_type',
    headerName: 'Asset Type',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'asset_address',
    headerName: 'Asset Address',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_protocol',
    headerName: 'Underlying Protocol',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_name',
    headerName: 'Underlying Name',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_type',
    headerName: 'Underlying Type',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_address',
    headerName: 'Underlying Address',
    minWidth: 120,
    width: 200,
    flex: 1
  }
];

const unpairedColumns = [
  { 
    field: 'asset_chain', 
    headerName: 'Chain',
    minWidth: 120,
    width: 200,
    flex: 1,
    colSpan: ({ row }) => {
      if (row?.span) {
        return 13
      }
      return 1;
    }
  },
  {
    field: 'strategy_protocol',
    headerName: 'Strategy Protocol',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_name',
    headerName: 'Strategy Name',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_attribute',
    headerName: 'Strategy Attribute',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_type',
    headerName: 'Strategy Type',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_protocol',
    headerName: 'Asset Protocol',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_name',
    headerName: 'Asset Name',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_type',
    headerName: 'Asset Type',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_address',
    headerName: 'Asset Address',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'underlying_protocol',
    headerName: 'Underlying Protocol',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_name',
    headerName: 'Underlying Name',
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_type',
    headerName: 'Underlying Type',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'underlying_address',
    headerName: 'Underlying Address',
    minWidth: 120,
    width: 200,
    flex: 1,
  }
];

const assetsColumns = [
  { 
    field: 'asset_chain',
    headerName: 'Chain', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_protocol',
    headerName: 'Strategy Protocol', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_name',
    headerName: 'Strategy Name', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_attribute',
    headerName: 'Strategy Attribute', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'strategy_type',
    headerName: 'Strategy Type', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_protocol',
    headerName: 'Asset Protocol', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_name',
    headerName: 'Asset Name', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_type',
    headerName: 'Asset Type', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'asset_address',
    headerName: 'Asset Address', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'underlying_protocol',
    headerName: 'Underlying Protocol', 
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_name',
    headerName: 'Underlying Name', 
    minWidth: 120,
    width: 200,
    flex: 1
  },
  {
    field: 'underlying_type',
    headerName: 'Underlying Type', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  {
    field: 'underlying_address',
    headerName: 'Underlying Address', 
    minWidth: 120,
    width: 200,
    flex: 1,
  }
];

const ordersColumns = [
  {
    field: 'id',
    headerName: 'ID', 
    flex: 0.5,
    minWidth: 50,
  },
  { 
    field: 'investor_full_name',
    headerName: 'Full Name', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'investor_account_code',
    headerName: 'Investor Account Code', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'type', 
    headerName: 'Type',
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'request_by_fund_units', 
    headerName: 'Fund Units',
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'request_by_fund_code', 
    headerName: 'Fund Code',
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'request_by_asset_units', 
    headerName: 'Asset Units', 
    type: 'number',
    minWidth: 120,
    width: 200,
    flex: 1,
  },
  { 
    field: 'request_by_asset_code', 
    headerName: 'Asset Code', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'request_by_asset_id', 
    headerName: 'Asset ID', 
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
  { 
    field: 'latest_status_', 
    headerName: 'Status',
    type: "singleSelect",
    minWidth: 120,
    width: 200,
    flex: 1,
    valueOptions: ['created', 'requested', 'executed', 'reported', 'completed'],
    renderCell: (cellValues) => {
      return (
      <Typography sx={{
        borderRadius: 1, 
        px:1,
        color: 'white',
        bgcolor: (() => {
          switch ( cellValues.value ) {
            case 'created' : return 'warning.main'
            case 'requested' : return 'warning.light'
            case 'executed' : return 'info.light'
            case 'reported' : return 'info.main'
            case 'completed' : return 'success.light'
            default : return 'error.main'
          }
        })()
      }}>
        {cellValues.value}
      </Typography>
      )
    }
  },  
  { 
    field: 'latest_status_datetime', 
    headerName: 'Status Datetime', 
    type: 'dateTime',
    minWidth: 120,
    width: 200,
    flex: 1,
  },  
];

export {
  resolutionColumns,
  unpairedColumns,
  compactHideAssetColumnNames,
  ordersColumns,
  assetsColumns,
};