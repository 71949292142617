import { 
  Box, 
  Typography,
  TextField,
  Accordion,
  AccordionSummary
} from '@mui/material';
import { 
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses
} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MyVerticalTable, VerticalTableIssuace, VerticalTableTransactions } from './verticalTable';
import { toISOStringWithTimezone } from '../utils';


export function EventTimeline( { data } ) {
  const timelineItems = data?.map((event, index) => {
    const eventType = event.event_type;
    const shownDateTime = toISOStringWithTimezone(new Date(event.created_at)).slice(0, -6);
    let eventSummary;
    let eventDetail;
    if (eventType === 'issuance') {
      const { details, ...issuanceFields } = event;
      const orderType = (
        event.type === 'management_fee' ?
        'management fee' :
        event.type === 'performance_fee' ?
        'performance fee' : 'investor'
      )
      eventSummary = <>{event?.type === 'redemption' ? 'Unissuing' : 'Issuing'} {event.fund_units} {orderType} units to {event.investor_account_code} by {event.created_by_email}</>
      eventDetail = (
        <Box sx={{ px: 1, my:2  }}>
          <TextField
            name="remarks"
            label="Remarks"
            value={event?.details?.remarks}
            disabled
            multiline
            minRows={2}
            fullWidth
            size="small"
            sx={{ mb:3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box>
            {VerticalTableIssuace(issuanceFields)}
          </Box>
        </Box>
      );
    } else if (eventType === 'transaction') {
      const { transaction_detail:transactionDetail, ...transactionEventDetail } = event;
      const splitDetailByType = Object.keys(transactionDetail).reduce((result, currKey) => {
        if (currKey.startsWith('from')) {
          result['from'][currKey] = transactionDetail[currKey];
        }
        else if (currKey.startsWith('to')) {
          result['to'][currKey] = transactionDetail[currKey];
        } 
        else {
          result['transaction'][currKey] = transactionDetail[currKey];
        }
        return result;
      }, {from: {}, to: {}, transaction: {}});
      eventSummary = (
        <>
          Adding Transaction #{event.transaction_id} by {event.created_by_email}
        </>
      );
      eventDetail = (
        <Box sx={{ px: 1, my: 2,display:'flex', flexDirection: 'column' }}>
          <Box sx={{ mb: 2 }}>
            <TextField
              name="remarks"
              label="Remarks"
              value={transactionEventDetail?.details?.remarks}
              disabled
              multiline
              minRows={2}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ display:'flex', mb:3 }}>
            <Box sx={{ flex: 1, pr:2 }}>
              <Typography variant='h5' sx={{ mb:1 }}>From</Typography>
              {MyVerticalTable(splitDetailByType.from)}
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant='h5' sx={{ mb:1 }}>To</Typography>
              {MyVerticalTable(splitDetailByType.to)}
            </Box>
          </Box>
          <Box>
            <Typography variant='h5' sx={{ mb:1 }}>Transaction</Typography>
            {VerticalTableTransactions(splitDetailByType.transaction)}
          </Box>
        </Box>
      )
    } else if (eventType === 'status') {
      eventSummary = (
        <>
          <Typography sx={{ 
            px:1, 
            mr:1, 
            borderRadius: 1, 
            display: 'inline-block', 
            color: 'white',
            bgcolor: (() => {
              switch ( event.status ) {
                case 'created' : return 'warning.main'
                case 'requested' : return 'warning.light'
                case 'executed' : return 'info.light'
                case 'reported' : return 'info.main'
                case 'completed' : return 'success.light'
                default : return 'error.main'
              }
            })()
          }}>
            {event.status}
          </Typography>
          by {event.created_by_email}
        </>
      );
      eventDetail = (
        <Box sx={{ px: 1, my:2  }}>
          <TextField
            name="remarks"
            label="Remarks"
            value={event?.details?.remarks}
            disabled
            multiline
            minRows={2}
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      );
    } else if (eventType === 'fee_update') {
        const { fee_params, ...feeUpdateField } = event;
        eventSummary = <>Update {event.type} by {event.created_by_email}</>
        eventDetail = (
        <Box sx={{ px: 1, my:2  }}>
            {MyVerticalTable({...feeUpdateField, ...fee_params})}
        </Box>
        )
    }
    return (
      <TimelineItem key={index}>
        <TimelineOppositeContent color="text.secondary" sx={{ mt:2, pl:0 }}>
          {shownDateTime}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot  sx={{ mt:3 }}/>
          {data.length === (index + 1) ? <></> : <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Accordion disableGutters elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              {eventSummary}
            </AccordionSummary>
              {eventDetail}
          </Accordion>
        </TimelineContent>
      </TimelineItem>
    )
  });
  
  return (
    <Timeline      
    sx={{
      [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.16,
      },
      p: 0
    }}
  >
    {timelineItems}
    </Timeline>
  )
};