import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack';
import { 
  Box, 
  Button, 
  Paper, 
  LinearProgress,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Download from '@mui/icons-material/Download';
import { lighten } from '@mui/material/styles';

import axiosFundOps from '../../rest-data-provider/axios';

import useTooltip from '../../hooks/useTooltip';
import { MyVerticalTable } from '../../components/verticalTable';
import { EventTimeline } from '../../components/eventTimeline';
import { 
  hashPositionRow, 
  stringifyError, 
} from '../../utils';
import { downloadFile } from '../../rest-data-provider/downloadFile';
import { GavFooter, NoRowsOverlay, SumUnitsFooter, renderNumber } from '../../components/dataGridComponent';

export function HistoricalOutstandingPositions() {
  
  const { fundCode, positionDateTime } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data:outstandingPositionsData } = useQuery({
    queryKey:[`positions-${fundCode}-${positionDateTime}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions/${positionDateTime}`, {params: {file_type: 'json'}});
      const roundedData = data.map((row) => {
        row.id = hashPositionRow(row);
        row.units = Number(row.units).toFixed(6);
        row.price_asset_to_platform = Number(row.price_asset_to_platform).toFixed(6);
        row.value_in_platform = Number(row.value_in_platform).toFixed(6);
        row.price_platform_to_denomination = Number(row.price_platform_to_denomination).toFixed(6);
        row.value_in_denomination = Number(row.value_in_denomination).toFixed(6);
        return row
      })
      return roundedData;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (err) => {enqueueSnackbar(stringifyError(err), { variant: 'error' })}
  });

  const { data:outstandingInvestorAccountsData } = useQuery({
    queryKey:[`outstanding-investor-account-${fundCode}-${positionDateTime}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/outstanding_investor_accounts`, {params: {position_datetime: positionDateTime}});
      return data;
    },  
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const { data:outstandingNavsData } = useQuery({
    queryKey:[`outstanding-nav-${fundCode}-${positionDateTime}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/outstanding_nav`, {params: {position_datetime: positionDateTime}});
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data:fundTimelineData } = useQuery({
    queryKey: [`fund-timeline-${fundCode}-${positionDateTime}`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/timeline`, { params: {position_datetime:positionDateTime} });
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return (
    <Box sx={{ flex:1, display:'flex', flexDirection:'column' }} className='historical-outstanding-position-detail'>
      <Box className='fundtab' sx={{ borderBottom: 1, borderColor: 'divider', flex:'0 0 auto' }}>
        <Tabs value={value} onChange={handleTabChange}>
          <Tab label="Outstanding NAV" />
          <Tab label="Outstanding Untraceable Positions"></Tab>
          <Tab label="Outstanding Investor Accounts" />
        </Tabs>
      </Box>
      <Box sx={{ flex:'1 1 auto', overflow:'auto', display:'flex' }} className='tab-content'>
        <Box sx={{ flex: value === 0 ? 1 : 0, display: value === 0 ? 'flex' : 'none', flexDirection:'column'}} className='outstanding-nav'>
          {value === 0 && <OutstandingNavs data={outstandingNavsData} fundTimelineData={fundTimelineData} />}
        </Box>
        <Box sx={{ flex: value === 1 ? 1 : 0, display: value === 1 ? 'flex' : 'none', flexDirection:'column' }} className='current-outstanding-position'>
          {value === 1 && <OutstandingPositions data={outstandingPositionsData} />}
        </Box>
        <Box sx={{ flex: value === 2 ? 1 : 0, display: value === 2 ? 'flex' : 'none', flexDirection:'column' }} className='investor-account'>
          {value === 2 && <OutstandingInvestorAccounts data={outstandingInvestorAccountsData} />}
        </Box>
      </Box>
    </Box>
  );
};

function OutstandingPositions({ data }){
    
    const [isCompactView, setIsCompactView] = useState(true);
    const { fundCode, positionDateTime } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const Historicalcolumns = [
      {
        field: 'wallet_name',
        headerName: 'Wallet Name',
        width: 200
      },
      {
        field: 'wallet_chain',
        type: 'singleSelect',
        headerName: 'Wallet Chain',
        width: 200
      },
      {
        field: 'wallet_custodial_account',
        headerName: 'Wallet Custodial Account',
        minWidth: 200,
      },
      { 
        field: 'wallet_address', 
        headerName: 'Wallet Address',
        width: 200,
      },
      {
        field: 'strategy_protocol',
        headerName: 'Strategy Protocol',
        width: 200,
      },
      {
        field: 'strategy_name',
        headerName: 'Strategy Name',
        width: 200,
      },
      {
        field: 'strategy_attribute',
        headerName: 'Strategy Attribute',
        width: 200,
      },
      {
        field: 'strategy_type',
        headerName: 'Strategy Type',
        type: 'singleSelect',
        valueOptions: [
          'holding',
          'lending',
          'borrowing',
          'collateral',
          'credit',
          'credit.locked'
        ],
        width: 200,
      },
      { 
        field: 'asset_chain', 
        headerName: 'Asset Chain',
        width: 200,
      },
      {
        field: 'asset_protocol',
        headerName: 'Asset Protocol',
        width: 200,
      },
      {
        field: 'asset_name',
        headerName: 'Asset Name',
        width: 200,
      },
      {
        field: 'asset_type',
        headerName: 'Asset Type',
        type: 'singleSelect',
        valueOptions: [
          'lp',
          'ib',
          'token',
          'future',
          'option_put',
          'option_call'
        ],
      },
      {
        field: 'asset_address',
        headerName: 'Asset Address',
        width: 200,
      },
      {
        field: 'underlying_protocol',
        headerName: 'Underlying Protocol',
        width: 200,
      },
      {
        field: 'underlying_name',
        headerName: 'Underlying Name',
        width: 200,
      },
      {
        field: 'underlying_type',
        headerName: 'Underlyting Type',
        width: 200,
      },
      {
        field: 'underlying_address',
        headerName: 'Underlying Address',
        width: 200,
      },
      {
        field: 'units',
        headerName: 'Units',
        width: 200,
        type: 'number',
      },
      {
        field: 'units_reference_datetime',
        headerName: 'Units Reference Datetime',
        width: 200,
        type: 'dateTime',
      },
      {
        field: 'price_asset_to_platform',
        headerName: 'Price Asset to Platform',
        width: 200,
        type: 'number',
        editable: true,
        renderCell: renderNumber
      },
      {
        field: 'price_asset_to_platform_reference_datetime',
        headerName: 'Price Asset to Platform Reference Datetime',
        width: 200,
        type: 'dateTime',
      },
      {
        field: 'value_in_platform',
        headerName: 'Value in Platform',
        width: 200,
        type: 'number',
        renderCell: renderNumber
      },
      {
        field: 'price_platform_to_denomination',
        headerName: 'Price Platform to Denomination',
        width: 200,
        type: 'number',
        editable: true,
        renderCell: renderNumber
      },
      {
        field: 'price_platform_to_denomination_reference_datetime',
        headerName: 'Price Platform to Denomination Reference Datetime',
        width: 200,
        type: 'dateTime',
      },
      {
        field: 'value_in_denomination',
        headerName: 'Value in Denomination',
        width: 200,
        type: 'number',
        renderCell: renderNumber
      },
      {
        field: 'apr',
        headerName: 'Apr',
        width: 200,
        type: 'number',
      },
    ];
    const compactHideColumnNames = [
      'wallet_address',
      'asset_chain',
      'asset_address',
      'underlying_protocol',
      'underlying_type',
      'underlying_address',
      'units_reference_datetime',
      'price_reference_datetime',
      'apr'
    ];
  
    const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);
  
    // compact view
    if (isCompactView) {
      Historicalcolumns.forEach((column) => {
        if (compactHideColumnNames.indexOf(column.field) > -1) {
          column.hide = true;
        }
        column.flex = 1;
      }); 
    } else Historicalcolumns.forEach((column) => {
      column.hide = false;
      column.flex = false;
    });
  
    const positionDataGrid = (
      <DataGrid
        columns={Historicalcolumns}
        density='compact'
        rows={data ? data : []}
        components={{
          Footer: GavFooter,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          footer: { 
            gav: data?.reduce((sum, row) => (sum + Number(row.value_in_denomination)), 0).toFixed(6), 
            numRow: data?.length
          },
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />
    )
  
    async function handleExport(){
      try {
        const response = await axiosFundOps.get(
          `/funds/${fundCode}/portfolio/positions/${positionDateTime}`,
          {
            params: {file_type: 'csv'},
            responseType: 'blob'
          }
        );
    
        enqueueSnackbar('Downloading');
        downloadFile(response.data, `outstanding_untraceable_positions_${fundCode}_${positionDateTime}.csv`)
      } catch (err) {
        enqueueSnackbar(stringifyError(err), { variant: 'error' })
      }
    }
  
    return (
      <Paper
        elevation={1}
        sx={{
          flex: '20 1 auto',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          // m: 1,
        }}
      >
          <Box sx={{ display:'flex', justifyContent:'space-between'}}>
            <Button
              startIcon={<Download />}
              onClick={handleExport}
            >
                Export to CSV
            </Button>
            <FormControlLabel 
              control={
                <Switch 
                  checked={isCompactView}
                  onChange={(event) => setIsCompactView(event.target.checked)}
                />
              } 
              label='Compact View' 
            />
          </Box>
          <Box sx={{
              flexGrow: 1,
              '& .unupdated': {
                bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9)
              },
              '& .MuiDataGrid-cell--withRenderer': {
                p: '5px'
              },
            }}
          >
            {positionDataGrid}
            {popperElement}
          </Box>
      </Paper>
    );
};

function OutstandingInvestorAccounts({ data }) {
  const navigate = useNavigate();
  
  const { fundCode, positionDateTime } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  async function handleExport(){
    try {
      const response = await axiosFundOps.get(
        `/funds/${fundCode}/outstanding_investor_accounts`,
        {
          params: {
            position_datetime: positionDateTime,
            file_type: 'csv'
          },
          responseType: 'blob'
        }
      );
  
      enqueueSnackbar('Downloading');
      downloadFile(response.data, `outstanding_investor_accounts_${fundCode}_${positionDateTime}.csv`)
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })
    }
  }

  const investorAccountsColumns = [
    { 
      field: 'code', 
      headerName: 'Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'name', 
      headerName: 'Name',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'portfolio_code', 
      headerName: 'Portfolio Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'units', 
      headerName: 'Units',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'fund_price', 
      headerName: 'Fund Price',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'value', 
      headerName: 'Value',
      minWidth: 120,
      width: 200,
      flex: 1,
    }
  ]

  const accountsTable = (
    <DataGrid 
      columns={investorAccountsColumns}
      rows={data ? data : []}
      autoHeight={false}
      density='compact'
      onRowClick={(params) => navigate(`/investors/${params.row.investor_id}/accounts/${params.row.code}`)}
      disableSelectionOnClick
      components={{
        LoadingOverlay: LinearProgress,
        Footer: SumUnitsFooter,
        NoRowsOverlay: NoRowsOverlay,
      }}
      componentsProps={{
        footer: { 
          sumUnits: data?.reduce((sum, row) => (sum + Number(row.units)), 0).toFixed(6),
          numRow: data?.length,
        }
      }}
    />
  );

  return (
    <>
      <Box>
        <Button
          startIcon={<Download />}
          onClick={handleExport}
        >
          Export to CSV
        </Button>
      </Box>
      {accountsTable}
    </>
  );
};

function OutstandingNavs({ data, fundTimelineData }) {
  
  const { fundCode, positionDateTime } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  async function handleExport(){
    try {
      const response = await axiosFundOps.get(
        `/funds/${fundCode}/outstanding_nav`,
        {
          params: {
            position_datetime: positionDateTime,
            file_type: 'csv'
          },
          responseType: 'blob'
        }
      );
  
      enqueueSnackbar('Downloading');
      downloadFile(response.data, `outstanding_nav_${fundCode}_${positionDateTime}.csv`)
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })
    }
  }

  const outstandingNavsTable = MyVerticalTable(data);
  const outstandingNavTimeline = fundTimelineData?.length > 0 ? (
    <Paper>
      <EventTimeline data={fundTimelineData} />
    </Paper>
  ) : <Paper sx={{ p:2 }}>No events referenced this record</Paper>

  return (
    <>
      <Box>
        <Button
            startIcon={<Download />}
            onClick={handleExport}
          >
            Export to CSV
          </Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        {outstandingNavsTable}
      </Box>
      <Box>
        <Typography variant='h5'>Timeline</Typography>
        {outstandingNavTimeline}
      </Box>
    </>
  )
};
  