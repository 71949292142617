import { Toolbar, Typography } from '@mui/material';
import Container from '@mui/material/Container';

export default function NotFound() {
  
  return (
    <Container maxWidth='xl'>
      <Toolbar />
      <Typography
        align='center'
        variant='h2'
      >
        404 Not Found.
      </Typography>
      <Typography
        align='center'
        variant='h3'
      >
        There's nothing here.
      </Typography>
    </Container>
  )
}