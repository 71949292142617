import { useState, useRef, useEffect } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { 
  Typography, 
  Box, 
  Paper, 
  LinearProgress, 
  Button, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  SpeedDial, 
  SpeedDialIcon,
  SpeedDialAction,
  CircularProgress,
  Divider, 
  Grid} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';

import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import { assetsColumns } from './columns';
import AlertDialogYesNo from '../components/alertDialogYesNo';
import { MyVerticalTable } from '../components/verticalTable';
import { EventTimeline } from '../components/eventTimeline';
import { MyBreadcrumbs } from '../components/breadcrumbs';
import { SingleSelectTable } from '../components/selectTable'

import { stringifyError, delay, formatNumber } from '../utils';

export default function Orders() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);
  const pageSize = 14;
  const ordersColumns = [
    {
      field: 'id',
      headerName: 'ID', 
      flex: 0.5,
      minWidth: 50,
    },
    { 
      field: 'investor_full_name',
      headerName: 'Full Name', 
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'investor_account_code',
      headerName: 'Investor Account Code', 
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'type', 
      headerName: 'Type',
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'request_by_fund_units', 
      headerName: 'Fund Units',
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'request_by_fund_code', 
      headerName: 'Fund Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'request_by_asset_units', 
      headerName: 'Asset Units', 
      type: 'number',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'request_by_asset_code', 
      headerName: 'Asset Code', 
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'request_by_asset_id', 
      headerName: 'Asset ID', 
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
    { 
      field: 'latest_status_', 
      headerName: 'Status',
      type: "singleSelect",
      minWidth: 120,
      width: 200,
      flex: 1,
      valueOptions: ['created', 'requested', 'executed', 'reported', 'completed'],
      renderCell: (cellValues) => {
        return (
        <Typography sx={{
          borderRadius: 1, 
          px:1,
          color: 'white',
          bgcolor: (() => {
            switch ( cellValues.value ) {
              case 'created' : return 'warning.main'
              case 'requested' : return 'warning.light'
              case 'executed' : return 'info.light'
              case 'reported' : return 'info.main'
              case 'completed' : return 'success.light'
              default : return 'error.main'
            }
          })()
        }}>
          {cellValues.value}
        </Typography>
        )
      }
    },  
    { 
      field: 'latest_status_datetime', 
      headerName: 'Status Datetime', 
      type: 'dateTime',
      minWidth: 120,
      width: 200,
      flex: 1,
    },  
  ];

  const investorId = searchParams.get('investor_id');
  const investorAccountCode = searchParams.get('investor_account_code');

  const { isLoading, isError, data, error } = useQuery([`orders-investor_id${investorId}-investor_account_code${investorAccountCode}-${page}`], async () => {
    const { data } = await axiosFundOps.get('/orders', {
        params: {
          investor_id:investorId, 
          investor_account_code:investorAccountCode,
          offset: page*pageSize, 
          limit: pageSize
        }
      });
    return data;
  }, { 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      data.forEach((order) => {
        if (order.latest_status) {
          order.latest_status_datetime = order.latest_status.datetime;
          order.latest_status_ = order.latest_status.status;
        }
      })
    }
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = isLoading ? 
    <CircularProgress /> :
    isError ?
    stringifyError(error) :
      <DataGrid 
        columns={ordersColumns}
        rows={data}
        loading={isLoading}
        pageSize={pageSize}
        rowCount={1000}
        onPageChange={(newPage) => setPage(newPage)}
        page={page}
        autoHeight
        rowsPerPageOptions={[pageSize]}
        pagination
        paginationMode="server"
        density='compact'
        onRowClick={(params) => navigate(`/orders/${params.id}`)}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />


  return (
    <Box className='orders'>
      <Box sx={{ mb: 2 }}>
        <Typography variant='h4'>Orders</Typography>
      </Box>
      <Box
        sx={{ 
          flex: '1 1 auto',
          overflow: 'hidden',
        }}
      >
        {table}
        {popperElement}
      </Box>
    </Box>
  )
};

export function OrderCreate() {
  
  const [searchParams] = useSearchParams();
  const investorAccountCode = searchParams.get('investor_account_code');

  const [ formValues, setFormValues ] = useState(
    {
      investor_account_code: investorAccountCode,
      type: "subscription",
      request_by_asset_id: '',
      request_by_asset_units: null,
      request_by_fund_units: null,
      remarks: null 
    }
  );
  const [open, setOpen] = useState(false);
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data:assetsData } = useQuery({
    queryKey: ['assets-strategy_protocol=wallet'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/assets', {params: {strategy_protocol: 'wallet'}});
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value === "" || value === undefined ? null : value,
    });
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const { remarks, ...payload } = formValues;
      payload.details = {remarks};
      const response = await axiosFundOps.post(`/orders/create`, payload);
      enqueueSnackbar('Order created. Redirect to order detail page', { variant: 'success' })
      await delay(500);
      navigate(`/orders/${response.data.id}`)

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const requestAssetSelectElement = SingleSelectTable(assetsData, assetsColumns, formValues.request_by_asset_id, ((value) => setFormValues({...formValues, request_by_asset_id: value})));

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create an order from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const orderForm = (
    <>
      <TextField 
        name="investorAccountCode"
        label="Investor Account"
        value={investorAccountCode}
        disabled
        size='small'
        sx={{ mr:2 }}
      />
      <FormControl size='small'>
        <InputLabel>Type</InputLabel>
        <Select
          value={formValues.type}
          name="type"
          label="type"
          onChange={handleInputChange}
        >
          <MenuItem value="subscription">subscription</MenuItem>
          <MenuItem value="redemption">redemption</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const assetForm = (
    <>
      <Typography>Asset</Typography>
      <Box sx={{ mb:2 }}>
        {requestAssetSelectElement}
      </Box>
      <Box>
        <TextField
          name="request_by_asset_units"
          label="Request Asset Units"
          value={formValues.request_by_asset_units}
          disabled={formValues.request_by_fund_units !== null}
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          size="small"
          onChange={handleInputChange}
          sx={{ mr:2, "& .MuiInputBase-root.Mui-disabled": { bgcolor: "rgba(0, 0, 0, 0.1)"} }}
        />
      </Box>
    </>
  );

  const fundForm = (
    <>
      <TextField
        name="request_by_fund_units"
        label="Request Fund Units"
        value={formValues.request_by_fund_units}
        disabled={formValues.request_by_asset_units !== null}
        type="number"
        onWheel={(e) => e.target.blur()}
        fullWidth
        size="small"
        onChange={handleInputChange}
        sx={{ "& .MuiInputBase-root.Mui-disabled": { bgcolor: "rgba(0, 0, 0, 0.1)"} }}
      />
    </>
  );

  const formInput = (
    <Box>
      <Box sx={{ mb: 3 }}>
        {orderForm}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>From</Typography>
        {formValues.type === 'subscription' ? assetForm : fundForm}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>To</Typography>
        {formValues.type === 'subscription' ? fundForm : assetForm}
      </Box>
      <Box>
        <TextField
          name="remarks"
          label="Remarks"
          value={formValues.remarks}
          multiline
          minRows={3}
          fullWidth
          size="small"
          onChange={handleInputChange}
        />
      </Box>
    </Box>
  );

  return (
    <Box className='order-create'>
      <Box  sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb:2 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}

export function Order() {
  
  const { orderId } = useParams();
  const outlet = useOutlet()
  const ref = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { isLoading, isError, data } = useQuery({
    queryKey: [`order-${orderId}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/orders/${orderId}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const {timeline: timelineData, details, ...orderDetailData} = isLoading ? 
    {timeline: [], details:{}} : 
    isError ?
    {timeline: [], details:{}} :
    data;
    
  async function handleClick(to) {
    navigate(to);
    await delay(500);
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }
  
  const orderDetailTable = MyVerticalTable({...orderDetailData, ...details});

  const speedDialButton = timelineData.at(-1)?.status === 'completed' ? <></> : (
    <Box sx={{ mt: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        direction='down'
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{ size: "small" }}
      >
      <SpeedDialAction
        key='status'
        icon={<PlaylistAddCheckIcon />}
        tooltipTitle='status'
        tooltipPlacement='right'
        tooltipOpen
        onClick={() => handleClick(`/orders/${orderId}/create/status`)}
      />
      <SpeedDialAction
        key='issuance'
        icon={<CurrencyExchangeIcon />}
        tooltipTitle='issuance'
        tooltipPlacement='right'
        tooltipOpen
        onClick={() => handleClick(`/orders/${orderId}/create/issuance`)}
      />
      <SpeedDialAction
        key='transaction'
        icon={<ReceiptLongIcon />}
        tooltipTitle='transaction'
        tooltipPlacement='right'
        tooltipOpen
        onClick={() => handleClick(`/orders/${orderId}/create/transaction`)}
      />
      </SpeedDial>
    </Box>
  );

  const orderTimeLineElement = timelineData?.length > 0 ? (
    <Box>
      <EventTimeline data={timelineData} />
    </Box>
  ) : <Paper sx={{ p:2 }}>No events referenced this record</Paper>

  return (
    <Box className='order'>
      <Box className='order-detail'>
        <Box sx={{ mb:3 }}>
          <MyBreadcrumbs ignoreParents={['create']} />
        </Box>
        <Box sx= {{ mb: 3 }}>
          {orderDetailTable}
        </Box>
        <Box>
          <Box sx={{ mb:1 }}>
            <Typography variant='h5'>Timeline</Typography>
          </Box>
          <Paper>
            {orderTimeLineElement}
            <Box sx={{ display:'flex' }}>
              <Box sx={{ flex: 0.3}}>
                {speedDialButton}
              </Box>
            </Box>
          </Paper>
        </Box>
        {!!outlet ? <Divider sx={{ my:2 }} /> : <></>}
      </Box>
      <Box ref={ref}>
        {outlet}
      </Box>
    </Box>
  );
}

export function OrderStatusCreate() {
  const [ formValues, setFormValues ] = useState({
    status: "created",
    remarks: null
  });
  const [open, setOpen] = useState(false);
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const { remarks, ...payload } = formValues;
      payload.details = { remarks };
      await axiosFundOps.post(`/orders/${orderId}/status/create`, payload);
      enqueueSnackbar('Order status created. Redirect to order detail page', { variant: 'success' })
      await delay(500);
      navigate(`/orders/${orderId}`)
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const formInput = (
    <Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <FormControl size='small' sx={{ mr:2 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={formValues.status}
            name="status"
            label="Status"
            fullWidth
            onChange={handleInputChange}
          >
            <MenuItem value="created">created</MenuItem>
            <MenuItem value="requested">requested</MenuItem>
            <MenuItem value="executed">executed</MenuItem>
            <MenuItem value="reported">reported</MenuItem>
            <MenuItem value="completed">completed</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TextField
        name="remarks"
        label="Remarks"
        value={formValues.remarks}
        type="text"
        fullWidth
        multiline
        minRows={3}
        onChange={handleInputChange}
        sx={{ display: 'block', mb:2 }}
      />
    </Box>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to update the order status?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='order-status-create'>
      <Box sx={{ mb:3 }}>
        <Typography variant='h5'>Update Status</Typography>
      </Box>
      <Box  sx={{ mb: 2 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}

export function OrderIssuanceCreate() {
  
  const { orderId } = useParams();
  const percentValueDiff = 0.000001;
  const [ formValues, setFormValues ] = useState({
    fund_price: null,
    fund_units: null,
    asset_id: null,
    asset_price: null,
    asset_units: null,
    fund_reference_price: null,
    fund_reference_price_type: 'nav_per_unit',
    remarks: null
  });

  const { data:assetsData } = useQuery({
    queryKey: ['assets-strategy_protocol=wallet'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/assets', {params: {strategy_protocol: 'wallet'}});
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data:orderDetail } = useQuery({ 
    queryKey: [`order-${orderId}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/orders/${orderId}`);
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false, 
  });


  const { data:latestFundNav } = useQuery({
    queryKey: [`outstanding-nav-${orderDetail?.request_by_fund_code}-latest`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${orderDetail?.request_by_fund_code}/outstanding_nav`);
      return data;
  }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!orderDetail,
  });
  useEffect(() => {
    setFormValues({
      ...formValues,
      fund_reference_price_type:orderDetail?.fund_reference_price_strict,
      fund_reference_price:latestFundNav?.[orderDetail?.fund_reference_price_strict],
      fund_price:latestFundNav?.[orderDetail?.fund_reference_price_strict],
    });      
  }, [latestFundNav, orderDetail])

  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const navigate = useNavigate();

  const fundValue = (formValues.fund_price == null || formValues.fund_units == null) ? 
  null : Number(formValues.fund_price * formValues.fund_units).toFixed(6);
  const assetValue = (formValues.asset_price == null || formValues.asset_units == null) ? 
  null : Number(formValues.asset_price * formValues.asset_units).toFixed(6);
  
  const isValueValid = (Math.abs(fundValue - assetValue) <= percentValueDiff * Math.min(fundValue, assetValue)) && (fundValue !== null && assetValue !== null);

  const fundPriceStep = formValues.fund_reference_price * 0.001 ;
  let percentPriceChange = formValues.fund_price ? 100*(formValues?.fund_price - formValues?.fund_reference_price)/formValues?.fund_reference_price : 0.00;

  percentPriceChange = percentPriceChange >= 0 ? `+${formatNumber(percentPriceChange, 4)}%` : `${formatNumber(percentPriceChange, 4)}%`;

  function handleInputChange(e) {
    const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value === "" ? null : value,
      });      
  };

  function handleFundReferencePriceChange(e) {
    const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value === "" ? null : value,
        fund_price:latestFundNav?.[value],
        fund_reference_price:latestFundNav?.[value],
        fund_reference_price_type:value
      });      
  };

  function handleInputNumberChange(e) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value === "" ? null : value < 0 ? 0.000000 : Number(value).toFixed(6),
    });  
  }

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);

      const { remarks, ...payload } = formValues;
      payload.details = { remarks };
      await axiosFundOps.post(`/orders/${orderId}/issuance/create`, payload);
      enqueueSnackbar('Order issuance created. Redirect to order detail page', { variant: 'success' })
      await delay(500);
      navigate(`/orders/${orderId}`)
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(500);
      setIsSubmitAwaiting(false);
    }
  };

  const assetSelectElement = SingleSelectTable(assetsData, assetsColumns, formValues.asset_id, ((value) => setFormValues({...formValues, 'asset_id': value})));

  const orderForm = (
    <>
      <TextField
        name="investor_account_code"
        label="Investor Account Code"
        key="investor_account_code"
        value={orderDetail?.investor_account_code}
        size='small'
        InputLabelProps={{
          shrink: true,
        }}
        disabled
        sx={{ pr:1 }}
      />
      <FormControl size='small'>
        <InputLabel>Type</InputLabel>
        <Select
          name="type"
          label="Type"
          value={orderDetail ? orderDetail.type : ""}
          disabled
        >
          <MenuItem value="subscription">subscription</MenuItem>
          <MenuItem value="redemption">redemption</MenuItem>
          <MenuItem value="management_fee">management_fee</MenuItem>
          <MenuItem value="performance_fee">performance_fee</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const assetForm = (
    <>
      <Typography>Asset</Typography>
      <Box sx={{ mb:2 }}>
        {assetSelectElement}
      </Box>
      <Box sx= {{ display: 'flex' }}>
        <TextField
          name="asset_price"
          label="Asset Price"
          key="asset_price"
          value={formValues.asset_price}
          size='small'
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          onChange={handleInputChange}
          onBlur={handleInputNumberChange}
          InputProps={{
            inputProps: { min: 0 }
          }}
          sx={{ mr:1 }}
        />
        <Typography sx={{ mr:1, pt:1 }}> × </Typography>
        <TextField
          name="asset_units"
          label="Asset Units"
          key="asset_units"
          value={formValues.asset_units}
          size='small'
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          onChange={handleInputChange}
          onBlur={handleInputNumberChange}
          sx={{ mr:1 }}
        />
        <Typography sx={{ mr:1, pt:1 }}> = </Typography>
        <TextField
          name="asset_value"
          label="Asset Value"
          key="asset_value"
          className="asset_value"
          value={assetValue}
          size='small'
          disabled
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {borderColor: isValueValid ? "success.light" : "error.light"}}}}
        />
      </Box>
    </>
  );

  const fundForm = (
    <>
      <Box sx={{ display: 'flex', mb:2}}>
        <TextField
          name="fund_reference_portfolio_code"
          label="Fund Reference Portfolio Code"
          key="fund_reference_portfolio_code"
          value={orderDetail?.request_by_fund_code}
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          disabled
          sx={{ pr:1, flex:1 }}
        />
        <TextField
          name="fund_reference_datetime"
          label="Fund Reference Datetime"
          key="fund_reference_datetime"
          value={latestFundNav?.datetime.slice(0, -6)}
          size='small'
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ pr:1, flex:1 }}
          fullWidth
          disabled
        />
        <FormControl size='small' sx={{ flex:1 }}>
          <InputLabel InputLabelProps={{ shrink: true }}>Fund Reference Price</InputLabel>
          <Select
            name="fund_reference_price"
            key="fund_reference_price"
            label="Fund Reference Price"
            value={formValues.fund_reference_price_type}
            onChange={handleFundReferencePriceChange}
          >
            <MenuItem value="nav_per_unit">{latestFundNav?.nav_per_unit} (nav_per_unit)</MenuItem>
            <MenuItem value="gav_per_unit_before_management_fee">{latestFundNav?.gav_per_unit_before_management_fee} (gav_per_unit_before_management_fee)</MenuItem>
            <MenuItem value="gav_per_unit_before_performance_fee">{latestFundNav?.gav_per_unit_before_performance_fee} (gav_per_unit_before_performance_fee)</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display:'flex', mb: 1 }}>
        <TextField
          name="fund_price"
          label="Fund Price"
          key="fund_price"
          value={formValues.fund_price}
          size='small'
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          onChange={handleInputChange}
          onBlur={handleInputNumberChange}
          sx={{ mr:1 }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: fundPriceStep
          }}
          InputProps={{
            endAdornment: (
              <>
                <Typography color='primary'>{percentPriceChange}</Typography> 
              </>
            )
          }}
        />
        <Typography sx={{ mr:1, pt:1 }}> × </Typography>
        <TextField
          name="fund_units"
          label="Fund Units"
          key="fund_units"
          value={formValues.fund_units}
          size='small'
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          onChange={handleInputChange}
          onBlur={handleInputNumberChange}
          sx={{ mr:1 }}
        />
        <Typography sx={{ mr:1, pt:1 }}> = </Typography>
        <TextField
          name="fund_value"
          label="Fund Value"
          key="fund_value"
          value={fundValue}
          size='small'
          color="success"
          disabled
          type="number"
          onWheel={(e) => e.target.blur()}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{"& .MuiOutlinedInput-root.Mui-disabled":{"& > fieldset": {borderColor: isValueValid ? "success.light" : "error.light"}}}}
        />
      </Box>
    </>
  );

  const formInput = (
    <Box>
      <Box sx={{ mb: 3 }}>
        {orderForm}
      </Box>
      <Typography variant='h6' sx={{ mb:3 }}>From</Typography>
      <Box sx={{ mb:3 }}>
        {orderDetail?.type === 'subscription' ? assetForm : fundForm}
      </Box>
      <Typography variant='h6' sx={{ mb:1 }}>To</Typography>
      <Box sx={{ mb:3 }}>
        {orderDetail?.type === 'subscription' ? fundForm : assetForm}
      </Box>
      <Box sx={{ mb:3 }}>
        <TextField
          name="remarks"
          label="Remarks"
          value={formValues.remarks}
          fullWidth
          multiline
          minRows={3}
          onChange={handleInputChange}
          sx={{ display: 'block' }}
        />
      </Box>
    </Box>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create an issuance from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting || !isValueValid}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='order-issuance-create'>
      <Box sx={{ my:3 }}>
        <Typography variant='h5'>{orderDetail?.type === 'subscription' ? 'Issue' : 'Unissue'} Fund Units</Typography>
      </Box>
      <Box className='form'>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}

export function OrderTransactionCreate() {
  const [ formValues, setFormValues ] = useState({
    transaction_id: null,
    remarks: null
  });
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const navigate = useNavigate();
  const { orderId } = useParams();

  const { isError, data } = useQuery({
    queryKey:[`transaction-${formValues.transaction_id}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/transactions/${formValues.transaction_id}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!formValues.transaction_id
  });
  const { associate_order_ids, ...transactionData } = data || {};

  const splitDetailByType = Object.keys(transactionData).reduce((result, currKey) => {
    if (currKey.startsWith('from')) {
      result['from'][currKey] = transactionData[currKey];
    }
    else if (currKey.startsWith('to')) {
      result['to'][currKey] = transactionData[currKey];
    } 
    else {
      result['transaction'][currKey] = transactionData[currKey];
    }
    return result;
  }, {from: {}, to: {}, transaction: {}});

  const transactionTableDetail = !formValues.transaction_id ? <></> : (
    <Box sx={{ flex: isError ? 0 : 5 }}>
      <Box sx={{ display:'flex', mb:3 }}>
        <Box sx={{ flex: 1, pr:3 }}>
          <Typography variant='h5' sx={{ mb:1 }}>From</Typography>
          {MyVerticalTable(splitDetailByType.from)}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant='h5' sx={{ mb:1 }}>To</Typography>
          {MyVerticalTable(splitDetailByType.to)}
        </Box>
      </Box>
      <Box>
          <Typography variant='h5' sx={{ mb:1 }}>Transaction</Typography>
        {MyVerticalTable(splitDetailByType.transaction)}
      </Box>
    </Box>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);

      const { remarks, ...payload } = formValues;
      payload.details = { remarks };
      await axiosFundOps.post(`/orders/${orderId}/transaction/create`, payload);
      enqueueSnackbar('Order transaction created. Redirect to order detail page', { variant: 'success' })
      await delay(500);
      navigate(`/orders/${orderId}`)
    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const formInput = (
    <Box sx={{ flex:1, mb:1 }}>
      <Box sx={{ display: 'flex'}}>
        <TextField
          name="transaction_id"
          label="Transaction ID"
          values={formValues.transaction_id}
          type="number"
          onWheel={(e) => e.target.blur()}
          size="small"
          onBlur={handleInputChange}
          sx={{ 
            mb: 2,
            ".css-1d3z3hw-MuiOutlinedInput-notchedOutline" : { borderColor: isError || !data ? "warning.main" : "success.light" }
          }}
        />
      </Box>
      <TextField
        name="remarks"
        label="Remarks"
        values={formValues.remarks}
        fullWidth
        multiline
        minRows={3}
        onChange={handleInputChange}
        sx={{ display: 'block', mb:2 }}
      />
    </Box>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to add the transaction?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting || isError || !data}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='order-transaction-create'>
      <Box sx={{ mb:3 }}>
        <Typography variant='h5'>Add Transaction</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        {formInput}
        {transactionTableDetail}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}