const config = {
  ColumnHash: [
      "wallet_name",
      "wallet_chain",
      "wallet_address",
      "asset_chain",
      "strategy_protocol",
      "strategy_name",
      "strategy_attribute",
      "strategy_type",
      "asset_protocol",
      "asset_name",
      "asset_type",
      "asset_address",
      "underlying_protocol",
      "underlying_name",
      "underlying_type",
      "underlying_address"
  ],
  MaxReferenceDeltaMinute: 60,
  MaxMarkingDeltaMinute: 60*12
}

export default config;