import { Dialog, DialogTitle, Button, DialogActions } from '@mui/material';

export default function AlertDialogYesNo({ 
    open,
    handleClose,
    handleYes,
    dialogTitle
  }) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id='alert-dialog-title'>
        {dialogTitle}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color='error' variant='outlined'>No</Button>
        <Button onClick={() => {handleYes(); handleClose(); }} autoFocus variant='contained'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}