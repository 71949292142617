import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { Resizable } from 're-resizable';
import { useSnackbar } from 'notistack';
import { 
  Box, 
  Button, 
  IconButton, 
  TextField, 
  InputAdornment,
  LinearProgress,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,

} from '@mui/material';
import { 
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  timelineOppositeContentClasses
} from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { lighten } from '@mui/material/styles';

import AlertDialogYesNo from '../../components/alertDialogYesNo';
import { GavFooter, NoRowsOverlay, renderNumber } from '../../components/dataGridComponent';

import axiosFundOps from '../../rest-data-provider/axios';

import useTooltip from '../../hooks/useTooltip';
import { hashPositionRow, delay, stringifyError, toISOStringWithTimezone } from '../../utils';
import config from '../../config'
import { isObsoleted } from '../../utils/obsoletedCheck';

export function CurrentOutstandingPositions() {

  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [outstandingPositions, setOutstandingPositions] = useState([]);
  const [previousPositions, setPreviousPositions] = useState([]);
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [currentPositionsInfo, setCurrentPositionsInfo] = useState({gav:0, unitsIssued:0, percentGavChange:0});
  const [editCells, setEditCells] = useState([]);
  const { fundCode } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data:outstandingNavsData } = useQuery({
    queryKey: [`outstanding-navs-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions`);
      return data;
    }, 
    staleTime: Infinity
  });

  const { 
    isLoading:outstandingInvestorAccountsIsLoading ,
    data:outstandingInvestorAccountsData 
  } = useQuery({
    queryKey: [`outstanding-investor-account-${fundCode}-current`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/outstanding_investor_accounts`);
      return data;
    }, 
    staleTime: Infinity,
  });

  const { refetch, data:outstandingPositionsData } = useQuery({
    queryKey: [`positions-${fundCode}-create`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions/create`);
      const roundedData = data.map((row) => {
        row.id = hashPositionRow(row);
        row.units = Number(row.units).toFixed(6);
        row.price_asset_to_platform = Number(row.price_asset_to_platform).toFixed(6);
        row.value_in_platform = Number(row.value_in_platform).toFixed(6);
        row.price_platform_to_denomination = Number(row.price_platform_to_denomination).toFixed(6);
        row.value_in_denomination = Number(row.value_in_denomination).toFixed(6);
        return row
      })
      return roundedData;
    }, 
    staleTime: Infinity
  });

  const previousPositionDateTime = outstandingNavsData?.at(0)?.datetime;

  const { refetch:prevRefetch, data:previousPositionsData } = useQuery([`positions-${fundCode}-${previousPositionDateTime}`], async () => {
    const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions/${previousPositionDateTime}`, {params: {file_type: 'json'}});
    const roundedData = data.map((row) => {
      row.id = hashPositionRow(row);
      row.units = Number(row.units).toFixed(6);
      row.price_asset_to_platform = Number(row.price_asset_to_platform).toFixed(6);
      row.value_in_platform = Number(row.value_in_platform).toFixed(6);
      row.price_platform_to_denomination = Number(row.price_platform_to_denomination).toFixed(6);
      row.value_in_denomination = Number(row.value_in_denomination).toFixed(6);
      return row
    })
    return roundedData;
  }, { 
    staleTime: Infinity,
    enabled: !!previousPositionDateTime,
    onError: (error) => {
      console.error(error);
      setPreviousPositions([])
    }
  });

  useEffect(() => {
    if (!!previousPositionsData) {
      setPreviousPositions(previousPositionsData)
    }
  }, [previousPositionsData])

  useEffect(() => {
    if (!!outstandingPositionsData) {
      setOutstandingPositions(outstandingPositionsData);
    }
  }, [outstandingPositionsData]);

  useEffect(() => {
    if (!!outstandingPositions && !!outstandingInvestorAccountsData) {
      const gav = outstandingPositions.reduce((sum, row) => (sum + Number(row.value_in_denomination)), 0).toFixed(6);
      const unitsIssued = outstandingInvestorAccountsData.reduce((sum, row) => (sum + Number(row.units)), 0).toFixed(6);
      const prevGav = previousPositions.reduce((sum, row) => (sum + Number(row.value_in_denomination)), 0).toFixed(6);
      const percentGavChange = ((gav - prevGav)/prevGav * 100).toFixed(4);
      setCurrentPositionsInfo({ gav, unitsIssued, percentGavChange });
    }
  }, [outstandingPositions, outstandingInvestorAccountsData, previousPositions]);

  const nowDateTime = new Date();
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const current_datetime = toISOStringWithTimezone(new Date(new Date().setHours(0,0,0,0)));
      let response;
      if(open === 'normal') {
        response = await axiosFundOps.post(`/funds/${fundCode}/portfolio/outstandings/create`, {positions: outstandingPositions});
      } 
      else {
        response = await axiosFundOps.post(`/funds/${fundCode}/portfolio/outstandings/create`, 
        {
          positions: outstandingPositions.map((row) => {
            row.units_reference_datetime = current_datetime;
            row.price_asset_to_platform_reference_datetime = current_datetime;
            row.price_platform_to_denomination_reference_datetime = current_datetime;
            return row
          }), 
          details: {"remarks": "This outstanding NAV record was submitted with time-rounded data."}
        }, 
        { params: { current_datetime }});
      } 
      enqueueSnackbar('Position submitted, Redirect to new created marking', { variant: 'success' })
      await delay(500);
      navigate(`/funds/${fundCode}/portfolios/${response.data.datetime}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const buttons = <>
    <Button
      onClick={() => setOpen('rounding')}
      startIcon={<DoneAllIcon />}
      color='primary'
      disabled={
        isSubmitAwaiting || (nowDateTime > new Date(2023, 1, 24))
      }
    >
      {isSubmitAwaiting ? 'Submitting' :'Submit with time rounding'}
    </Button>
    <Button
      onClick={() => setOpen('normal')}
      startIcon={<DoneAllIcon />}
      disabled={
        isSubmitAwaiting || (
          nowDateTime >= new Date(2023, 0, 24) && nowDateTime <= new Date(2023, 1, 24)
        )
      }
    >
      {isSubmitAwaiting ? 'Submitting' :'Submit'}
    </Button>
  </>;

  const alertBox = (
    <>
      <PositionsSubmitDialog
        fundCode={fundCode}
        open={open === 'rounding'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to submit outstanding positions with time rounding?'
        currentPositionsData={currentPositionsInfo}
        />
      <PositionsSubmitDialog 
        fundCode={fundCode}
        open={open === 'normal'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to submit outstanding positions?'
        currentPositionsData={currentPositionsInfo}
      />
    </>
  );

  return (
    <Box sx={{ flex:1, display:'flex', flexDirection:'column' }} className='current-outstanding-position-detail'>
      {alertBox}
      <Box className='fund-tab' 
        sx={{ 
          borderBottom: 1, 
          borderColor: 'divider', 
          flex:'0 0 auto',
          display: 'flex',
          justifyContent:'space-between'
        }}
      >
        <Box>
          <Tabs value={value} onChange={handleTabChange}>
            <Tab disabled label="Outstanding NAV" />
            <Tab label="Outstanding Untraceable Positions"></Tab>
            <Tab label="Outstanding Investor Accounts" />
          </Tabs>
        </Box>
        <Box>
          {buttons}
        </Box>
      </Box>
      <Box sx={{ flex:'1 1 auto', overflow:'hidden', display:'flex' }} className='tab-content'>
        <Box sx={{ flex: value === 1 ? 1 : 0, display: value === 1 ? 'flex' : 'none', flexDirection:'column'}} className='current-outstanding-position'>
          <OutstandingPositions
            positions={outstandingPositions}
            setPositions={setOutstandingPositions}
            previousPosition={previousPositions}
            editCells={editCells}
            setEditCells={setEditCells}
            refetch={refetch}
            prevRefetch={prevRefetch}
            currentPositionsData={currentPositionsInfo}
          />
        </Box>
        <Box sx={{ flex: value === 2 ? 1 : 0, display: value === 2 ? 'flex' : 'none', flexDirection:'column' }} className='investor-account'>
          <OutstandingInvestorAccounts
            outstandingInvestorAccountsData={outstandingInvestorAccountsData}
            isLoading={outstandingInvestorAccountsIsLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

function OutstandingPositions({
  positions, 
  setPositions, 
  previousPosition,
  editCells,
  setEditCells,
  refetch,
  prevRefetch,
  currentPositionsData
}) {
  const compactColumns = [
    'actions',
    'wallet_custodial_account',
    'wallet_chain',
    'wallet_name',
    'strategy_protocol',
    'strategy_name',
    'strategy_attribute',
    'strategy_type',
    'asset_protocol',
    'asset_name',
    'asset_type',
    'underlying_name',
    'units',
    'price_asset_to_platform',
    'value_in_platform',
    'price_platform_to_denomination',
    'value_in_denomination'
  ];

  const { fundCode } = useParams();
  const [open, setOpen] = useState(false);
  const [isCompactView, setIsCompactView] = useState(false);
  const [focusDeleteId, setFocusDeleteId] = useState(null);
  const [positionsColumns, setPositionsColumns] = useState([
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      maxWidth: 50,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <IconButton
            label='Edit'
            onClick={handleDeleteClick(id)}
            color='inherit'
          >
            <DeleteIcon />
          </IconButton>
        ];
      },
    },
    {
      field: 'wallet_name',
      headerName: 'Wallet Name',
      width: 200,
      editable: true,
      type: 'singleSelect',
    },
    {
      field: 'wallet_chain',
      headerName: 'Wallet Chain',
      type: 'singleSelect',
      width: 200,
      editable: true
    },
    {
      field: 'wallet_custodial_account',
      headerName: 'Wallet Custodial Account',
      minWidth: 160,
      editable: true,
      type: 'singleSelect',
    },
    { 
      field: 'wallet_address', 
      headerName: 'Wallet Address',
      width: 200,
    },
    {
      field: 'strategy_protocol',
      headerName: 'Strategy Protocol',
      width: 200,
      editable: true
    },
    {
      field: 'strategy_name',
      headerName: 'Strategy Name',
      width: 200,
      editable: true
    },
    {
      field: 'strategy_attribute',
      headerName: 'Strategy Attribute',
      width: 200,
      editable: true
    },
    {
      field: 'strategy_type',
      headerName: 'Strategy Type',
      type: 'singleSelect',
      valueOptions: [
        'holding',
        'lending',
        'borrowing',
        'collateral',
        'credit',
        'credit.locked'
      ],
      width: 200,
      editable: true
    },
    { 
      field: 'asset_chain', 
      headerName: 'Asset Chain',
      width: 200,
    },
    {
      field: 'asset_protocol',
      headerName: 'Asset Protocol',
      width: 200,
      editable: true
    },
    {
      field: 'asset_name',
      headerName: 'Asset Name',
      width: 200,
      // minWidth: 170,
      editable: true
    },
    {
      field: 'asset_type',
      headerName: 'Asset Type',
      type: 'singleSelect',
      width: 200,
      valueOptions: [
        'lp',
        'ib',
        'token',
        'future',
        'option_put',
        'option_call'
      ],
      editable: true
    },
    {
      field: 'asset_address',
      headerName: 'Asset Address',
      width: 200,
      editable: true,
    },
    {
      field: 'underlying_protocol',
      headerName: 'Underlying Protocol',
      width: 200,
      editable: true,
    },
    {
      field: 'underlying_name',
      headerName: 'Underlying Name',
      width: 200,
      editable: true,
    },
    {
      field: 'underlying_type',
      headerName: 'Underlying Type',
      width: 200,
      editable: true,
    },
    {
      field: 'underlying_address',
      headerName: 'Underlying Address',
      width: 200,
      editable: true,
    },
    {
      field: 'units',
      headerName: 'Units',
      width: 200,
      type: 'number',
      editable: true,
      renderCell: renderNumber
    },
    {
      field: 'units_reference_datetime',
      headerName: 'Units Reference Datetime',
      width: 200,
      type: 'dateTime',
    },
    {
      field: 'price_asset_to_platform',
      headerName: 'Price Asset to Platform',
      width: 200,
      type: 'number',
      editable: true,
      renderCell: renderNumber
    },
    {
      field: 'price_asset_to_platform_reference_datetime',
      headerName: 'Price Asset to Platform Reference Datetime',
      width: 200,
      type: 'dateTime',
    },
    {
      field: 'value_in_platform',
      headerName: 'Value in Platform',
      width: 200,
      type: 'number',
      renderCell: renderNumber
    },
    {
      field: 'price_platform_to_denomination',
      headerName: 'Price Platform to Denomination',
      width: 200,
      type: 'number',
      editable: true,
      renderCell: renderNumber
    },
    {
      field: 'price_platform_to_denomination_reference_datetime',
      headerName: 'Price Platform to Denomination Reference Datetime',
      width: 200,
      type: 'dateTime',
    },
    {
      field: 'value_in_denomination',
      headerName: 'Value in Denomination',
      width: 200,
      type: 'number',
      renderCell: renderNumber
    },
    {
      field: 'apr',
      headerName: 'Apr',
      width: 200,
      type: 'number',
      editable: true
    },
  ]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [diffAlert, setDiffAlert] = useState(10);
  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(positions);
  const { isLoading, data:dataOptions } = useQuery({
    queryKey: [`wallets-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/wallets`);
      return data;
    }, 
    staleTime: Infinity 
  });

  useEffect(() => {
    // wallet_custodial_account
    const positionsColumnsOptions = positionsColumns.slice();
    positionsColumnsOptions[1].valueOptions = [...new Set(dataOptions?.map(x => x.custodial_account_name))];
    // wallet_chain
    positionsColumnsOptions[2].valueOptions = ({ row }) => ([...new Set(
      dataOptions
        ?.filter(x => x.custodial_account_name === row.wallet_custodial_account)
        ?.map(x => x.wallet_chain)
    )])
    // wallet_name
    positionsColumnsOptions[3].valueOptions = ({ row }) => ([...new Set(
      dataOptions
        ?.filter(x => x.custodial_account_name === row.wallet_custodial_account)
        ?.filter(x => x.wallet_chain === row.wallet_chain)
        ?.map(x => x.wallet_name)
    )])
    setPositionsColumns(positionsColumnsOptions);
  }, [dataOptions])

  useEffect(() => {
    handleCompactView();
  }, [])

  const handleAddClick = () => {
    const newRow = { 
      wallet_custodial_account: dataOptions[0]?.custodial_account_name,
      wallet_chain: '',
      wallet_name: '',
      wallet_address: '',
      strategy_protocol: '',
      strategy_name: '',
      strategy_attribute: '',
      strategy_type: '',
      asset_chain: '',
      asset_protocol: '',
      asset_name: '',
      asset_type: '',
      asset_address: '',
      underlying_protocol: '',
      underlying_name: '',
      underlying_type: '',
      underlying_address: '',
      external_position_id: 0,
      units: 0,
      units_reference_datetime: toISOStringWithTimezone(new Date()),
      price_asset_to_platform: 0,
      price_asset_to_platform_reference_datetime: toISOStringWithTimezone(new Date()),
      value_in_platform: 0,
      price_platform_to_denomination: 0,
      price_platform_to_denomination_reference_datetime: toISOStringWithTimezone(new Date()),
      value_in_denomination: 0,
      isEdited: true,
      isNew: true 
    }
    newRow.id = hashPositionRow(newRow);
    newRow.id = Math.max(...positions.map(row => row.id))+1;

    setPositions((oldPosition) => [
      newRow,
      ...oldPosition
    ]);
  };

  const handleDeleteClick = (id) => () => {
    setOpen(true);
    setFocusDeleteId(id);
  };

  function handleYesDelete() {
    setPositions(positions.filter((row) => row.id !== focusDeleteId));
  };

  function handleCompactView() {
    if (!isCompactView) {
      setPositionsColumns(
        positionsColumns.map((column) => {
          if (compactColumns.indexOf(column.field) === -1) {
            column.hide = true;
          }
          column.flex = 1;
          return column;
        })
      )
    } else {
      setPositionsColumns(
        positionsColumns.map((column) => {
          column.hide = false;
          column.flex = false;
          return column
        })
      )
    }
    setIsCompactView(!isCompactView);
  };

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setPositions(positions.map(
      (row) => {
        if (row.id === newRow.id) {
          updatedRow.asset_chain = updatedRow.wallet_chain;

          let remainedOpitions = [...dataOptions];
          if (newRow.wallet_name !== oldRow.wallet_name){
            remainedOpitions = dataOptions
              .filter(x => x.custodial_account_name === updatedRow.wallet_custodial_account)
              .filter(x => x.wallet_chain === updatedRow.wallet_chain)
              .filter(x => x.wallet_name === updatedRow.wallet_name);
          } 
          else if (newRow.wallet_chain !== oldRow.wallet_chain){
            remainedOpitions = dataOptions
              .filter(x => x.custodial_account_name === updatedRow.wallet_custodial_account)
              .filter(x => x.wallet_chain === updatedRow.wallet_chain)

            updatedRow.wallet_name = remainedOpitions[0]?.wallet_name
          } 
          else if (newRow.wallet_custodial_account !== oldRow.wallet_custodial_account){
            remainedOpitions = dataOptions
              .filter(x => x.custodial_account_name === updatedRow.wallet_custodial_account)

            updatedRow.wallet_chain = remainedOpitions[0]?.wallet_chain
            updatedRow.wallet_name = remainedOpitions[0]?.wallet_name
          } else {
            remainedOpitions = dataOptions
              .filter(x => x.custodial_account_name === updatedRow.wallet_custodial_account)
              .filter(x => x.wallet_chain === updatedRow.wallet_chain)
              .filter(x => x.wallet_name === updatedRow.wallet_name);
          }

          updatedRow.wallet_address = remainedOpitions?.length === 0 ? '' : remainedOpitions[0].wallet_address;

          // set reference_datetime unit, price
          if (row.units !== updatedRow.units) {
            updatedRow.units = updatedRow.units.toFixed(6);
            updatedRow.units_reference_datetime = toISOStringWithTimezone(new Date());
          }
          if (row.price_asset_to_platform !== updatedRow.price_asset_to_platform){
            updatedRow.price_asset_to_platform = updatedRow.price_asset_to_platform.toFixed(6);
            updatedRow.price_asset_to_platform_reference_datetime = toISOStringWithTimezone(new Date());
          } 
          if (row.price_platform_to_denomination !== updatedRow.price_platform_to_denomination){
            updatedRow.price_platform_to_denomination = updatedRow.price_platform_to_denomination.toFixed(6);
            updatedRow.price_platform_to_denomination_reference_datetime = toISOStringWithTimezone(new Date());
          } 
          
          // calculate value
          updatedRow.value_in_platform = (updatedRow.units * updatedRow.price_asset_to_platform).toFixed(6);
          updatedRow.value_in_denomination = (updatedRow.value_in_platform * updatedRow.price_platform_to_denomination).toFixed(6);
          // updatedRow.isEdited = true;
          return updatedRow
        } else {
          return row
        }
      }
    ));
    return updatedRow;
  };

  const deleteDialogueBox = (
    <AlertDialogYesNo 
      open={open}
      handleClose={() => setOpen(false)}
      handleOpen={() => setOpen(true)}
      handleYes={handleYesDelete}
      dialogTitle='Are you sure you want to delete this record?'
    />
  )
  
  const toolbar = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Button
          onClick={() => {handleAddClick(); handleCompactView(false);}}
          startIcon={<AddIcon />}
          sx={{ mr:2 }}
        >
          Add record
        </Button>
        <Button
          onClick={() => {refetch(); setEditCells([]); prevRefetch();}}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button>
      </Box>
      <Box>
        <TextField
          type="number"
          onWheel={(e) => e.target.blur()}
          placeholder='Difference %'
          value={diffAlert}
          variant='outlined'
          size='small'
          InputProps={{ 
            inputProps: { min: 0, max: 100 },
            startAdornment: <InputAdornment position='start'>%</InputAdornment>
          }}
          onChange={(event) => {
            let value = event.target.value;
            if (value) {
              setDiffAlert(value);
            } else {
              setDiffAlert(0);
            }
          }}
          sx={{ 
            width: 100,
            mr: 1
          }}
        />
        <FormControlLabel 
          control={
            <Switch 
              checked={isCompactView}
              onChange={handleCompactView}
            />
          } 
          label='Compact View' 
        />
      </Box>
    </Box>
  );

  const missingAssets = previousPosition.filter((prevAsset) => !positions.map(currAsset => currAsset.id).includes(prevAsset.id));

  const newPositionDataGrid = (
    <Box sx={{
      flex: '1 1 auto',
      height: '100%',
      overflow:'hidden',
      display:'flex',
      flexDirection: 'column'
    }}>
      {toolbar}
      <Box
        sx={{ 
          flex:'1 1 auto', 
          '& .obsoleted': {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9)
          },
          '& .edited-cell': {
            fontWeight: 900,
          },
          '& .significant-difference': {
            bgcolor: (theme) => lighten(theme.palette.error.main, 0.9)
          },
          '& .MuiDataGrid-cell--withRenderer': {
            p: '5px'
          },
      }}>
      <DataGrid
        rows={positions}
        columns={positionsColumns}
        density='compact'
        loading={isLoading}
        // disableColumnMenu
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={error => console.error(error)}
        experimentalFeatures={{ newEditingApi: true }}
        getRowClassName={(params) => {
          let className = '';
          const nowTime = new Date();
          const isUnitsReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.units_reference_datetime), config.MaxReferenceDeltaMinute)
          const isPriceAssetToPlatformReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.price_asset_to_platform_reference_datetime), config.MaxReferenceDeltaMinute)
          const isPricePlatformToDenominationReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.price_platform_to_denomination_reference_datetime), config.MaxReferenceDeltaMinute)
          if (
            isUnitsReferenceDateTimeObsoleted
            || isPriceAssetToPlatformReferenceDateTimeObsoleted
            || isPricePlatformToDenominationReferenceDateTimeObsoleted
          ) {
            className += 'obsoleted ';
          }
          return className;
        }}
        getCellClassName={(params) => {
          let className = '';
          if (editCells.some(el => el.id === params.id && el.field === params.field)) {
            className += 'edited-cell'
          } 

          if (
            params.field === 'units' 
            || params.field === 'price_asset_to_platform' 
            || params.field === 'value_in_platform'
            || params.field === 'price_platform_to_denomination'
            || params.field === 'value_in_denomination'
          ) {
            const previousRow = previousPosition?.find((r) => r?.id === params.id)
            if (previousRow === undefined) { // no previous position
              className += ' significant-difference';
            }
            const previousValue = previousRow?.[params.field];
            const percentDiff = Math.abs((params.value - previousValue)/previousValue);
            if (percentDiff >= diffAlert/100 || isNaN(percentDiff)) {
              className += ' significant-difference'
            }
          }

          return className
        }}
        onCellEditStop={(params) => {
          setEditCells((prevState) => [...new Set([...prevState, {id: params.id, field: params.field}])])
        }}
        components={{
          Footer: GavFooter,
          LoadingOverlay: LinearProgress
        }}
        componentsProps={{
          footer: { 
            gav: currentPositionsData.gav,
            numRow: positions?.length
          },
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />

      </Box>
      {popperElement}
    </Box>
  );

  const missingAssetDataGrid = missingAssets?.length === 0 ? (<></>) : (
    <Resizable defaultSize={{height: 100}} style={{ display: 'flex', flexDirection:'column' }}>
      <Button
        size='small'
        onClick={() => {
          const addedRow = previousPosition.filter((row) => selectedRow.indexOf(row.id) > -1);
          addedRow.forEach((row) => {row.isEdited=true})
          setPositions((oldPosition) => [...oldPosition, ...addedRow])
          setSelectedRow([]);
        }}
        sx={{
          alignSelf: 'start'
        }}
      >
        Add selected to current positions
      </Button>
      <Box 
        sx={{ flex: 1, display: 'flex', flexDirection:'column'}} 
      >
        <Box sx={{
          flexGrow: 1,
          '& .obsoleted': {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9)
          },
          '& .MuiDataGrid-cell--withRenderer': {
            p: '5px'
          },
        }}
        >
          <DataGrid
            rows={missingAssets}
            columns={positionsColumns}
            isCellEditable={() => false}
            density='compact'
            checkboxSelection
            // disableColumnSelector
            onSelectionModelChange={(newSelectionModel) => {
              setSelectedRow(newSelectionModel);
            }}
            getRowClassName={(params) => {
              let className = '';
              const nowTime = new Date();
              const isUnitsReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.units_reference_datetime), config.MaxReferenceDeltaMinute)
              const isPriceAssetToPlatformReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.price_asset_to_platform_reference_datetime), config.MaxReferenceDeltaMinute)
              const isPricePlatformToDenominationReferenceDateTimeObsoleted = isObsoleted(nowTime, new Date(params.row.price_platform_to_denomination_reference_datetime), config.MaxReferenceDeltaMinute)
              if (
                isUnitsReferenceDateTimeObsoleted
                || isPriceAssetToPlatformReferenceDateTimeObsoleted
                || isPricePlatformToDenominationReferenceDateTimeObsoleted
              ) {
                className += 'obsoleted ';
              }
              return className;
            }}
            hideFooter
            componentsProps={{
              cell: {
                onMouseEnter: handlePopperOpen,
                onMouseLeave: handlePopperClose,
              },
            }}
          />
          {popperElement}
        </Box>
      </Box>
    </Resizable>
  )

  return (
    <Box
      className='outstanding-untraceable-positions'
      elevation={10}
      sx={{ 
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {deleteDialogueBox}
      <Box sx={{
        flex: '1 1 auto'
      }}>
        {newPositionDataGrid}
      </Box>
      <Box>
        {missingAssetDataGrid}
      </Box>
    </Box>
  );
};

function OutstandingInvestorAccounts({
  outstandingInvestorAccountsData,
  isLoading
})  {
  const navigate = useNavigate();

  const investorAccountsColumns = [
    { 
      field: 'code', 
      headerName: 'Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'name', 
      headerName: 'Name',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'fund_code', 
      headerName: 'Fund Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'units', 
      headerName: 'Units',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
  ]
  const accountsTable = (
    <DataGrid 
      columns={investorAccountsColumns}
      rows={outstandingInvestorAccountsData ? outstandingInvestorAccountsData : []}
      autoHeight={true}
      loading={isLoading}
      density='compact'
      onRowClick={(params) => navigate(`/investors/${params.row.investor_id}/accounts/${params.row.code}`)}
      disableSelectionOnClick
      hideFooter
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: NoRowsOverlay,
      }}
    />
  );

  return (
    <Box>{accountsTable}</Box>
  );
};

function PositionsSubmitDialog({
  fundCode,
  open,
  handleClose,
  handleYes,
  dialogTitle,
  currentPositionsData
}) {

  const { data:fundTimelineData } = useQuery({
    queryKey: [`fund-timeline-${fundCode}-current`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/timeline`);
      return data;
    },
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!!fundTimelineData && fundTimelineData.at(-1)?.event_type === 'newMarking') {
      fundTimelineData.splice(fundTimelineData.length - 1, 1, {
        event_type: 'newMarking', 
        created_at: new Date(),
        ...currentPositionsData
      })
    } else if (!!fundTimelineData && currentPositionsData.gav && currentPositionsData.unitsIssued) {
      fundTimelineData.push({
        event_type: 'newMarking', 
        created_at: new Date(),
        ...currentPositionsData
      });
    }
  }, [
    currentPositionsData.gav,
    currentPositionsData.unitsIssued,
    fundTimelineData,
    open
  ]);

  const dialogContent = (
    <>
      <Typography
        sx={{ mb: 5 }}
      >
        ⚠️ The following events occurred after the last NAV marking. Careful inspection is required before you proceed any further.
      </Typography>
      <SubmitEventTimelineShort data={fundTimelineData} />
    </>
  );
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='xl'
    >
      <DialogTitle id='alert-dialog-title'>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='error' variant='outlined'>No</Button>
        <Button onClick={() => {handleYes(); handleClose(); }} autoFocus variant='contained'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SubmitEventTimelineShort( { data } ) {
  const timelineItems = data?.map((event, index) => {
    const eventType = event.event_type;
    const shownDateTime = toISOStringWithTimezone(new Date(event.created_at)).slice(0, -6);
    let eventSummary;
    let timelineDotVarint = 'filled';
    if (eventType === 'issuance') {
      const orderType = (
        event.type === 'management_fee' ?
        'management fee' :
        event.type === 'performance_fee' ?
        'performance fee' : 'investor'
      )
      eventSummary = <>{event?.type === 'redemption' ? 'Unissuing' : 'Issuing'} {event.fund_units?.toFixed(6)} {orderType} units to {event.investor_account_code} by {event.created_by_email}</>
    } else if (eventType === 'transaction') {
      eventSummary = (
        <>
          Adding Transaction #{event.transaction_id} by {event.created_by_email}
        </>
      );
    } else if (eventType === 'status') {
      eventSummary = (
        <>
          <Typography sx={{ 
            px:1, 
            mr:1, 
            borderRadius: 1, 
            display: 'inline-block', 
            color: 'white',
            bgcolor: (() => {
              switch ( event.status ) {
                case 'created' : return 'warning.main'
                case 'requested' : return 'warning.light'
                case 'executed' : return 'info.light'
                case 'reported' : return 'info.main'
                case 'completed' : return 'success.light'
                default : return 'error.main'
              }
            })()
          }}>
            {event.status}
          </Typography>
          by {event.created_by_email}
        </>
      );
    } else if (eventType === 'fee_update') {
        eventSummary = <>Update {event.type} by {event.created_by_email}</>
    } else if (eventType === 'marking') {
      eventSummary = (
        <>
          Marking an outstanding portfolio with GAV of {event.gross_asset_value?.toFixed(6)} and fund units of {event.units_issued?.toFixed(6)} by {event.created_by_email}
        </>
      );
    } else if (eventType === 'newMarking') {
      eventSummary = (
        <>
          Marking an outstanding portfolio with GAV of {event.gav} <strong>({event.percentGavChange > 0 ? '+' : ''}{event.percentGavChange}%)</strong> and fund units of {event.unitsIssued}
        </>
      );
      timelineDotVarint = 'outlined';
    }
    return (
      <TimelineItem key={index}>
        <TimelineOppositeContent color="text.secondary" sx={{ pl:0 }}>
          {shownDateTime}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot 
            sx={{ mt:1.3 }}
            variant={timelineDotVarint}
          />
          {data.length === (index + 1) ? <></> : <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          {eventSummary}
        </TimelineContent>
      </TimelineItem>
    )
  });
  
  return (
    <Timeline      
    sx={{
      [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.16,
      },
      p: 0
    }}
  >
    {timelineItems}
    </Timeline>
  )
};
