import axiosFundOps from "./rest-data-provider/axios";


export const authProvider = {
  login: async ({ username, password }) => {

    const response = await axiosFundOps.post(`/users/login`, 
      new URLSearchParams({
        'grant_type': '',
        'username': username,
        'password': password,
        'scope': '',
        'client_id': '',
        'client_secret': ''
      }),
    )

    if (response.status === 200) {
      const { access_token, token_type } = response.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('username', username);

      axiosFundOps.defaults.headers.common = {
        Authorization: `Bearer ${access_token}`,
      };

      return {
          success: true,
          redirectTo: "/",
      };
  }

    return {
        success: false,
        error: {
            message: "Invalid credentials",
            name: "Invalid credentials",
        },
    };
  },
  logout: () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: () => {
    const token = localStorage.getItem('access_token');
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: () => {
    const token = localStorage.getItem('access_token');
    const username = localStorage.getItem('username');
    if (token) {
      return {
        username: username
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
