import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { capitalizeFirstLetter } from '../utils/strings';


export function MyBreadcrumbs({ ignoreParents = [], mapper = {} }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  
  const pathnames = pathname.split("/").filter(Boolean);

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        let shownName = mapper.hasOwnProperty(name) ? mapper[name] : name
        shownName = Object.values(params).indexOf(name) === -1 ? capitalizeFirstLetter(name) : name;
        shownName = mapper.hasOwnProperty(index) ? mapper[index] : shownName

        return isLast || ignoreParents.includes(name) ? (
          <Typography key={name} variant='h5'>
            {shownName}
          </Typography>
        ) : (
          <Link
            variant='h5'
            underline='hover' 
            key={name} 
            onClick={() => navigate(routeTo)} 
            sx={{ 
              cursor: 'pointer',
              display: "flex",
              alignItems: "center",
            }}
          >
            {shownName}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}