import { useMemo } from "react";
import { useParams, useNavigate, useOutlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { 
  Box, 
  CircularProgress, 
  Paper,
  Button,
  useTheme,
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';

import { 
  Line,
  Area,
  ComposedChart,
  CartesianGrid, 
  XAxis, 
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label
} from 'recharts';

import moment from 'moment'
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import axiosFundOps from '../rest-data-provider/axios';
import { stringifyError } from '../utils';
import { MyBreadcrumbs } from '../components/breadcrumbs';

export default function OutstandingNavs(){
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { fundCode } = useParams();
  const { data } = useQuery({
    queryKey: [`outstanding-navs-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions`);
      return data;
    },
    staleTime: Infinity,
  });
  const { positionDateTime } = useParams();
  const currentDateTimeId = data?.findIndex((object) => object.datetime === positionDateTime);

  const buttons = positionDateTime ? (
    <Box className='datetime-navigator'>
      <Button 
        variant='outlined' 
        startIcon={<NavigateBeforeIcon/>} 
        sx={{mr:1}}
        disabled={currentDateTimeId === data?.length-1}
        onClick={() => {navigate(`/funds/${fundCode}/portfolios/${data?.at(currentDateTimeId+1)?.datetime}/`)}}
      >
        previous
      </Button>
      <Button 
        variant='outlined' 
        endIcon={<NavigateNextIcon />}
        disabled={currentDateTimeId === 0}
        onClick={() => {navigate(`/funds/${fundCode}/portfolios/${data?.at(currentDateTimeId-1)?.datetime}/`)}}
      >
        next
      </Button>
    </Box>
  ) : (
  <Box>
    <Button 
      onClick={() => navigate(`/funds/${fundCode}/portfolios/current_position/`)} 
      startIcon={<AddIcon />}
      size='small'
    >
      create
    </Button>
  </Box>
  );

  return (
    <Box className='outstanding-navs' sx={{ flex: 1, display: 'flex', flexDirection:'column' }}>
      <Box className='fund-portfolio-header' sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <MyBreadcrumbs />
        {buttons}
      </Box>
      <Box className='outstanding-navs-content' sx={{ display: 'flex', flexDirection:'column', flex: 1 }}>
        {outlet}
      </Box>
    </Box>
  )
};

export function OutstandingNavsTimeSeriesChart() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { fundCode } = useParams();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`outstanding-navs-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/portfolio/positions`);
      return data;
    },
    staleTime: Infinity,
  });

  const transformedData = useMemo(() => {
    const transformedData = data?.map((dataPoint, index) => {
      const currentParseDatetime = moment(dataPoint.datetime);
      if (index === data.length-1) {
        dataPoint.month = currentParseDatetime.format("MMM");
        dataPoint.year = currentParseDatetime.format("YYYY");
      } else {
        const previousParseDatetime = moment(data[index+1].datetime);
        const currentMonth = currentParseDatetime.format("MMM");
        const currentYear = currentParseDatetime.format("YYYY");
        const previousMonth = previousParseDatetime.format("MMM");
        const previousYear = previousParseDatetime.format("YYYY");

        dataPoint.month = currentMonth !== previousMonth ? currentMonth : null;
        dataPoint.year = currentYear !== previousYear ? currentYear : null;
      }
      return dataPoint
    });
    return transformedData
  }, [data]);

  const lineElement = (
    <>
      <Line 
        type="stepBefore" 
        dataKey='last_performance_fee_issuance_high_water_mark'
        name='HWM'
        stroke={theme.palette.warning.dark} 
        fill={theme.palette.warning.dark}
        dot={false}
        animationDuration={100}
      />
      <Line 
        type="linear" 
        dataKey='gav_per_unit_before_management_fee'
        name='GAV/UNIT XM'
        stroke={grey[500]} 
        fill={grey[500]}
        dot={false}
        animationDuration={100}
        strokeDasharray="1 2"
        />
      <Line 
        type="linear" 
        dataKey='gav_per_unit_before_performance_fee'
        name='GAV/UNIT XP'
        stroke={grey[700]} 
        fill={grey[700]}
        dot={false}
        animationDuration={100}
        strokeDasharray="1 2"
        />
      <Line 
        type="linear" 
        dataKey='nav_per_unit'
        name='NAV/UNIT'
        strokeWidth={3}
        stroke={theme.palette.primary.main}
        fill={theme.palette.primary.main}
        dot={false}
        animationDuration={100}
        // activeDot={{ r:7, onClick: (e, dataPoint) => navigate(`/funds/${fundCode}/portfolios/${dataPoint.payload.datetime}`) }}
        activeDot={{ r:7 }}
      />
      <Area 
        type="linear" 
        dataKey='gross_asset_value'
        name='GAV'
        yAxisId="right"
        strokeWidth={0}
        stroke={grey[700]} 
        fill={grey[700]}
        dot={false}
        opacity={0.25}
        activeDot={false}
        animationDuration={100}
      />
    </>
  );
  const chartElement = isLoading ? <CircularProgress /> : 
  isError ? stringifyError(error) : (
    <ResponsiveContainer width="99%" height="100%" debounce={1}>
      <ComposedChart data={transformedData} margin={{ left:20, top: 20, right:20 }} onClick={(params) => navigate(`/funds/${fundCode}/portfolios/${params.activeLabel}`)}>
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
        <XAxis 
          xAxisId="0" 
          dataKey="datetime" 
          tickFormatter={value => moment(value).format('D')}
          reversed={true}
        />
        <XAxis xAxisId="1" dataKey="month" reversed={true} tickLine={false} ticks={transformedData.map((dataPoint) => dataPoint.month)} axisLine={false} dy={-17} />
        <XAxis xAxisId="2" dataKey="year" reversed={true} tickLine={false} ticks={transformedData.map((dataPoint) => dataPoint.year)} axisLine={false} dy={-30}  />
        <YAxis 
          type="number" 
          tickCount={20}
          domain={([dataMin, dataMax]) => ([Math.round(Math.max(1.3*dataMin - 0.3*dataMax, 0)), Math.round(1.3*dataMax - 0.3*dataMin)])}
        >
          <Label position='insideLeft' offset={-5} angle={-90} value='NAV/UNIT' fill={blue[700]} />
        </YAxis>
        <YAxis 
          type="number" 
          yAxisId="right" 
          orientation="right" 
          tickCount={20}
          domain={([dataMin, dataMax]) => ([Math.round(Math.max(1.3*dataMin - 0.3*dataMax, 0)), Math.round(1.3*dataMax - 0.3*dataMin)])}  
        >
          <Label position='right' offset={5} angle={-90} value='GAV' fill={grey[700]} />
        </YAxis>
        <Tooltip offset={100} isAnimationActive={false} />
        <Legend 
          wrapperStyle={{fontSize: "1rem"}}
          layout='horizontal'
          verticalAlign="top" 
          align="center" 
          height={30}
          iconType="plainline" 
        />
        {lineElement}
        {/* <Brush dataKey="datetime" height={30} fill={grey[50]} /> */}
      </ComposedChart>
    </ResponsiveContainer>    
  );

  return (
    // <Box className='timeseries-chart'>
      <Paper sx={{ flex:1, display: 'flex', flexDirection: 'column' }}>
        {chartElement}
      </Paper>
    // </Box>
  )
};