export function downloadFile(data, filename) {
    const newBlob = new Blob([data]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    window.URL.revokeObjectURL(blobUrl);
  }