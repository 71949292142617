import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { 
  Typography, 
  Box, 
  LinearProgress, 
  Button, 
  TextField,
  Grid, 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save'
import { lighten } from '@mui/material/styles';

import { useSnackbar } from 'notistack';

import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import AlertDialogYesNo from '../components/alertDialogYesNo';

import { 
  stringifyError, 
  delay, 
} from '../utils';
import { MyBreadcrumbs } from '../components/breadcrumbs';
import { MyVerticalTable } from '../components/verticalTable';

export default function Custodians() {
  
  
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`custodians`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/custodians');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const custodiansColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 5
    },
    { 
      field: 'code',
      headerName: 'Code',
      flex: 1,
    },
    { 
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
  ]

  const table = isError ?
    stringifyError(error) :
    (
      <>
        <DataGrid 
          columns={custodiansColumns}
          rows={data ? data : []}
          autoHeight
          hideFooter
          disableSelectionOnClick
          loading={isLoading}
          density='compact'
          onRowClick={(params) => navigate(`/custodians/${params.row.code}/`)}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            cell: {
              onMouseEnter: handlePopperOpen,
              onMouseLeave: handlePopperClose,
            },
          }}
        />
        {popperElement}
      </>
    )


  return (
    <Box className='custodians'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Custodians</Typography>
        <Button
          onClick={() => navigate('/custodians/create/')}
          startIcon={<AddIcon />}
          size='small'
          disableElevation
        >
          Create Custodian
        </Button>
      </Box>
        <Box
          sx={{ 
            flex: '1 1 auto',
            overflow: 'hidden',
            '& .unupdate': {
              bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9),
            }
          }}
        >
          {table}
        </Box>
    </Box>
  )
};

export function CustodianCreate() {
  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
      name: "",
      address: null,
      remarks: null 
  });

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const { remarks, ...payload } = formValues;
      payload.details = { remarks }
      const response = await axiosFundOps.post(`/custodians/create`, payload);
      enqueueSnackbar(`Custodians ${response.data.code} created. Redirect to detail page`, { variant: 'success' })
      await delay(1000);
      navigate(`/custodians/${response.data.code}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const formInput = (
    <>
      <Box sx={{ mb:2 }}>
        <TextField
          name="code"
          label="Code"
          value={formValues.code}
          size="small"
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ mb:2 }}>
        <TextField
          name="name"
          label="Name"
          value={formValues.name}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        <TextField
          name="remarks"
          label="Remarks"
          value={formValues.remarks}
          fullWidth
          multiline
          minRows={3}
          onChange={handleInputChange}
        />
      </Box>
    </>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a new custodian from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='custodian-create'>
      <Box  sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb:2 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};

export function CustodianDetail() {
  
  const { custodianCode } = useParams();
  const navigate = useNavigate();

  const { isLoading, isError, data: custodianData } = useQuery({
    queryKey: [`custodian-${custodianCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/custodians/${custodianCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data: custodialAccountsData } = useQuery({
    queryKey: [`custodial-account-custodian-code-${custodianCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(
        `/custodial_accounts`,
        {params: {custodian_code: custodianCode}}
      );
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const custodianDetailTable = MyVerticalTable(custodianData);

  const custodialAccountsTable = <CustodialAccountsTable data={custodialAccountsData} />

  return (
    <Box className='custodian-detail'>
      <Box sx={{ mb:2 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb:3 }}>
        {custodianDetailTable}
      </Box>
      <Box>
        <Grid container justifyContent='space-between' sx={{ mb: 1 }}>
        <Typography variant='h6'>Custodial Accounts</Typography>
        <Button 
          size='small' 
          onClick={() => navigate(`/custodians/${custodianCode}/custodial_accounts/create/`)}
          startIcon={<AddIcon />}
          sx={{ mr:1 }} 
        >
          Create Custodial Accounts
        </Button>
        </Grid>
        <Box>
          {custodialAccountsTable}
        </Box>
      </Box>
    </Box>
  )
};

function CustodialAccountsTable( { data } ){
  const { custodianCode } = useParams();
  const navigate = useNavigate();
  const custodialAccountsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 5
    },
    { 
      field: 'code',
      headerName: 'Code',
      flex: 1,
    },
    { 
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
  ]

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = (
    <DataGrid 
      columns={custodialAccountsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      disableSelectionOnClick
      density='compact'
      onRowClick={(params) => navigate(`/custodians/${custodianCode}/custodial_accounts/${params.row.code}`)}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      components={{
        LoadingOverlay: LinearProgress,
      }}
    />
  )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
};

export function CustodialAccountCreate() {
  
  const { custodianCode } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
    "custodian_code": custodianCode,
    "code": "",
    "name": ""
  });

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const response = await axiosFundOps.post(`/custodial_accounts/create`, formValues);
      enqueueSnackbar(`Custodial accounts ${response.data.code} created. Redirect to custodian ${custodianCode} detail page`, { variant: 'success' })
      await delay(1000);
      navigate(`/custodians/${custodianCode}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a custodial account from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const formInput = (
    <>
      <Box>
        <Box sx={{ mb:2 }}>
          <TextField
            name="code"
            label="Code"
            required
            value={formValues.code}
            size="small"
            fullWidth
            onChange={handleInputChange}
          />
        </Box>
        <Box>
          <TextField
            name="name"
            label="Name"
            required
            value={formValues.name}
            size="small"
            fullWidth
            onChange={handleInputChange}
          />
        </Box>
      </Box>
    </>
  );

  return (
    <Box className='create-custodian'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs ignoreParents={['custodial_accounts']} />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}

export function CustodialAccountDetail() {
  
  const { custodialAccountCode } = useParams();

  const { data: custodialAccountData } = useQuery({
    queryKey: [`custodial_account-${custodialAccountCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/custodial_accounts/${custodialAccountCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data: wallets } = useQuery({
    queryKey: [`wallets-custodial_account-${custodialAccountCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/wallets`, { params: { custodial_account_code: custodialAccountCode } });
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const custodianDetailTable = MyVerticalTable(custodialAccountData);
  const custodialAccountWallets = <WalletsTable data={wallets} />

  
  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <MyBreadcrumbs ignoreParents={['custodial_accounts']} />
      </Box>
      <Box sx={{ mb: 3 }}>
        {custodianDetailTable}
      </Box>
      <Box>
        <Typography variant='h6' sx={{mb: 1}}>Wallets</Typography>
        <Box>
          {custodialAccountWallets}
        </Box>
      </Box>
    </Box>
  )
}

function WalletsTable( { data } ){
  const fundWalletsColumns = [
    { 
      field: 'wallet_name',
      headerName: 'Wallet Name',
      flex: 1,
    },
    { 
      field: 'wallet_chain',
      headerName: 'Chain',
      flex: 0.5,
    },
    { 
      field: 'fund_code',
      headerName: 'Fund',
      flex: 1,
    },
    { 
      field: 'wallet_address',
      headerName: 'Address',
      flex: 1,
    },
  ];
  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = (
    <DataGrid 
      columns={fundWalletsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      disableSelectionOnClick
      density='compact'
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      components={{
        LoadingOverlay: LinearProgress,
      }}
    />
  )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
};