import { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  Menu, 
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Box
} from '@mui/material';
import { blue } from '@mui/material/colors';


import { authProvider } from '../authProvider';


export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const pages = ['Funds', 'Investors', 'Orders', 'Transactions', 'Chains', 'Custodians', 'Users', 'Oracles'];
  
  const appBarHeight = '50px';
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = async () => {
    handleCloseUserMenu();  
    await authProvider.logout()
    navigate('/login', { state: { from: location }, replace: true });
  };

  const authButton = authProvider.check().authenticated ? (
    <Box>
      <Button color='inherit' onClick={handleOpenUserMenu} size='small' variant='outlined'>
          {authProvider.getIdentity().username}
      </Button>
      <Menu
        sx={{ mt: 3 }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleLogOut}>
          <Typography textAlign='center'>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <Button 
      color='inherit'
      component={NavLink}
      to={'/login'}
      size='small' 
      variant='outlined'
    >
      Login
    </Button>
  )

  return (
    <Box className='App'>
      <AppBar component='nav' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: appBarHeight }}>
        <Toolbar variant='dense' sx={{ height: appBarHeight }} >
          <Typography
            variant='h6'
            noWrap
            component={NavLink}
            to='/'
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'PT Sans',
              fontWeight: 'bold',
              color: 'inherit',
              textDecoration: 'none',
              ':hover': {
                cursor: 'pointer'
              }
            }}
          >
            TULA
          </Typography>
          <Box sx={{ flex: 1, display: 'flex'}}>
            {pages.map((page) => (
              <Button
                key={page}
                component={NavLink}
                to={`/${page.toLowerCase()}`}
                sx={{ 
                  my: 1, 
                  color: 'white', 
                  display: 'block',
                  '&.active': {
                    background: blue[900]
                  }
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          {authButton}
        </Toolbar>
      </AppBar>
      <Box component='main' sx={{ overflow: 'auto', height: '100vh', display: 'flex', flexDirection:'column' }}>
        <Toolbar className='toolbar' variant='dense' sx={{ height: appBarHeight }} />
        <Container className='main-content' maxWidth='xl' sx={{ pt:2, pb:1, flex: 1, display: 'flex', flexDirection:'column' }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};
