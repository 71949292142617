import { useQuery } from '@tanstack/react-query';

import { 
  Typography, 
  Box, 
  LinearProgress,
  TextField,
  MenuItem,
  Button,
  Grid, 
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { lighten } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';

import { 
  delay,
  stringifyError, 
} from '../utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AlertDialogYesNo from '../components/alertDialogYesNo';
import { MyBreadcrumbs } from '../components/breadcrumbs';

export default function Users() {
  const navigate = useNavigate();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`users`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/users');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const usersColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 5
    },
    { 
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    { 
      field: 'title',
      headerName: 'Title',
      flex: 0.3,
    },
    { 
      field: 'first_name_en',
      headerName: 'First Name (EN)',
      flex: 1,
    },
    { 
      field: 'last_name_en',
      headerName: 'Last Name (EN)',
      flex: 1,
    },
    { 
      field: 'first_name_th',
      headerName: 'First Name (TH)',
      flex: 1,
    },
    { 
      field: 'last_name_th',
      headerName: 'Last Name (TH)',
      flex: 1,
    },
  ]

  const table = isError ?
    stringifyError(error) :
    <DataGrid 
      columns={usersColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      disableSelectionOnClick
      loading={isLoading}
      density='compact'
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
    />

  return (
    <Box className='users'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Users</Typography>
        <Button
          onClick={() => navigate('/users/create/')}
          startIcon={<AddIcon />}
          size='small'
          disableElevation
        >
          Create User
        </Button>
      </Box>
        <Box
          sx={{ 
            flex: '1 1 auto',
            overflow: 'hidden',
            '& .unupdate': {
              bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9),
            }
          }}
        >
          {table}
          {popperElement}
        </Box>
    </Box>
  )
};

export function UserCreate() {
  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
    email: null,
    password: null,
    confirm_password: null,
    title: null,
    first_name_th: null,
    first_name_en: null,
    last_name_th: null,
    last_name_en: null,
    phone_number: null,
    remarks: null,
  });

  const isPasswordMatched = formValues.password === formValues.confirm_password && !!formValues.password

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);

      const { remarks, confirm_password, ...payload } = formValues;
      payload.details = { remarks }

      const response = await axiosFundOps.post(`/users/create`, payload);
      enqueueSnackbar(`User ${response.data.email} created. Redirect to login page`, { variant: 'success' })
      await delay(1000);
      navigate(`/login`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const formInput = (
    <>
      <Box>
        <Box sx={{ mb:2 }}>
          <TextField
            name="email"
            label="Email"
            value={formValues.email}
            size="small"
            type='email'
            fullWidth
            required
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ display:'flex', mb:2 }}>
          <TextField
            name="password"
            label="Password"
            value={formValues.password}
            size="small"
            fullWidth
            required
            type="password"
            onChange={handleInputChange}
            sx={{ 
              pr: 2,
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: isPasswordMatched ? "success.light" : "error.light" }
              },
            }}
          />
          <TextField
            name="confirm_password"
            label="Confirm Password"
            value={formValues.confirm_password}
            size="small"
            fullWidth
            required
            type="password"
            onChange={handleInputChange}
            sx={{ 
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: isPasswordMatched ? "success.light" : "error.light" }
              }
            }}
          />
        </Box>
        <Typography variant="h6" sx={{ mb:1 }}>Information</Typography>
        <Box sx={{ mb:2 }}>
          <TextField
            name="title"
            label="Title"
            value={formValues.title}
            size="small"
            select
            fullWidth
            onChange={handleInputChange}
          >
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
          </TextField>
        </Box>
        <Box sx={{ mb:2, display:'flex' }}>
          <TextField
            name="first_name_en"
            key="first_name_en"
            label="First Name (EN)"
            value={formValues.first_name_en}
            type="text"
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ pr: 2 }}
          />
          <TextField
            name="last_name_en"
            key="lastt_name_en"
            label="Last Name (EN)"
            value={formValues.last_name_en}
            type="text"
            fullWidth
            size="small"
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ mb:2, display:'flex' }}>
          <TextField
            name="first_name_th"
            key="first_name_th"
            label="First Name (TH)"
            value={formValues.first_name_th}
            type="text"
            fullWidth
            size="small"
            onChange={handleInputChange}
            sx={{ pr: 2 }}
          />
          <TextField
            name="last_name_th"
            key="lastt_name_th"
            label="Last Name (TH)"
            value={formValues.last_name_th}
            type="text"
            fullWidth
            size="small"
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ mb:2 }}>
          <TextField
            name="phone_number"
            key="phone_number"
            label="Phone Number"
            value={formValues.phone_number}
            fullWidth
            size="small"
            onChange={handleInputChange}
          />
        </Box>
        <Box>
          <TextField
            name="remarks"
            label="Remarks"
            value={formValues.remarks}
            fullWidth
            multiline
            minRows={3}
            onChange={handleInputChange}
          />
        </Box>
      </Box>
    </>
  );

  const buttons = (
    <>
      <AlertDialogYesNo
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create new user from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting || !isPasswordMatched}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='create-user'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}