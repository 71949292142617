import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Typography, Box, LinearProgress, Button, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, IconButton, Breadcrumbs, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import SaveIcon from '@mui/icons-material/Save';

import { useSnackbar } from 'notistack';

import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import { ordersColumns } from './columns';
import AlertDialogYesNo from '../components/alertDialogYesNo';
import { MyVerticalTable } from '../components/verticalTable';
import { stringifyError, delay, formatNumber } from '../utils';
import { MyBreadcrumbs } from '../components/breadcrumbs';
import { GavFooter, NoRowsOverlay } from '../components/dataGridComponent';




export default function Investors() {
  
  const navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`investors`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/investors');
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const investorsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
    },
    { 
      field: 'investor_full_name',
      headerName: 'Full Name',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'type', 
      headerName: 'Type',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'email', 
      headerName: 'Email', 
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Orders',
      width: 70,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <IconButton
            label='viewInvestorOrder'
            onClick={() => navigate(`/orders/?investor_id=${id}`)}
          >
            <ViewListIcon />
          </IconButton>
        ];
      },
    }
  ];

  const table = isLoading ? 
    <CircularProgress /> :
    isError ?
    stringifyError(error) :
    <DataGrid 
      columns={investorsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      loading={isLoading}
      density='compact'
      onRowClick={(params) => navigate(`/investors/${params.id}`)}
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
    />

  return (
    <Box className='investors'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Investors</Typography>
        <Button
          onClick={() => navigate('/investors/create/')}
          startIcon={<AddIcon />}
          size='small'
          disableElevation
        >
            Create Investor
        </Button>
      </Box>
      <Box
        sx={{ 
          flex: '1 1 auto',
          overflow: 'hidden',
        }}
      >
        {table}
        {popperElement}
      </Box>
    </Box>
  )
};

export function InvestorCreate() {
  

  const defaultValue = {
    "type": "individual",
    "individual_national_id": null,
    "individual_passport_id": null,
    "individual_title": null,
    "individual_first_name": null,
    "individual_last_name": null,
    "juristic_id": null,
    "juristic_type": null,
    "juristic_name": null,
    "country": "THA",
    "phone_number": "",
    "email": ""
  };

  const [ formValues, setFormValues ] = useState(defaultValue);
  const countries = require("../utils/countries.json");
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (name === 'type') {
      setFormValues({
        ...defaultValue,
        [name]: value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const { email, ...payload } = formValues;
      payload.email = email?.toLowerCase();
      const response = await axiosFundOps.post(`/investors/create`, payload);
      enqueueSnackbar('Investor created. Redirect to investor detail page.', { variant: 'success' })
      await delay(500);
      navigate(`/investors/${response.data.id}/`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create an investor from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const countrySelection = (
    <FormControl size='small' sx={{ minWidth:'100px' }}>
      <InputLabel>Country</InputLabel>
      <Select
        value={formValues.country}
        name="country"
        key="country"
        label="Country"
        onChange={handleInputChange}
      >
        {countries.map((country) => <MenuItem key={country.alpha3} value={country.alpha3}>{country.name}</MenuItem>)}
      </Select>
    </FormControl>
  );

  const investorTypeForm = (
    <>
      <FormControl size='small'>
        <InputLabel>Type</InputLabel>
        <Select
          value={formValues.type}
          name="type"
          label="Type"
          onChange={handleInputChange}
        >
          <MenuItem value="individual">Individual</MenuItem>
          <MenuItem value="juristic">Juristic</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const investorContactInformationForm = (
    <>
      <TextField
        name="email"
        key="email"
        label="Email"
        value={formValues.email}
        fullWidth
        size="small"
        onChange={handleInputChange}
      />
      <TextField
        name="phone_number"
        key="phone_number"
        label="Phone Number"
        value={formValues.phone_number}
        fullWidth
        size="small"
        onChange={handleInputChange}
      />
    </>
  );

  const investorInformationForm = formValues.type === 'individual' ? (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <FormControl size='small' sx={{ minWidth:'100px', mr:2 }}>
          <InputLabel>Title</InputLabel>
          <Select
            value={formValues.individual_title}
            name="individual_title"
            key="individual_title"
            label="individual_title"
            onChange={handleInputChange}
          >
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Mrs.">Mrs.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
          </Select>
        </FormControl>
        <TextField
          name="individual_first_name"
          key="individual_first_name"
          label="First Name"
          value={formValues.individual_first_name}
          type="text"
          fullWidth
          size="small"
          onChange={handleInputChange}
          sx={{ pr: 2, flex:1 }}
        />
        <TextField
          name="individual_last_name"
          key="individual_last_name"
          label="Last Name"
          value={formValues.individual_last_name}
          type="text"
          size="small"
          fullWidth
          onChange={handleInputChange}
          sx={{ flex:1 }}
        />
      </Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          name="individual_national_id"
          key="individual_national_id"
          label="Individual National ID"
          value={formValues.individual_national_id}
          type="text"
          fullWidth
          size="small"
          onChange={handleInputChange}
          sx={{ pr: 2 }}
        />
        <TextField
          name="individual_passport_id"
          key="individual_passport_id"
          label="Individual Passport ID"
          value={formValues.individual_passport_id}
          type="text"
          fullWidth
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        {countrySelection}
      </Box>
    </>
  ) : ( 
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          name="juristic_name"
          key="juristic_name"
          label="Juristic Name"
          value={formValues.juristic_name}
          fullWidth
          size="small"
          onChange={handleInputChange}
          sx={{ pr: 2, flex:1 }}
        />
        <FormControl size='small' sx={{ minWidth:'150px'}}>
          <InputLabel>Juristic Type</InputLabel>
          <Select
            value={formValues.juristic_type}
            name="juristic_type"
            key="juristic_type"
            label="Juristic Type"
            onChange={handleInputChange}
          >
            <MenuItem value="Inc.">Inc.</MenuItem>
            <MenuItem value="Part., Ltd.">Part., Ltd.</MenuItem>
            <MenuItem value="Co., Ltd.">Co., Ltd.</MenuItem>
            <MenuItem value="Pub Co., Ltd.">Pub Co., Ltd.</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          name="juristic_id"
          key="juristic_id"
          label="Juristic ID"
          value={formValues.juristic_id}
          fullWidth
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <Box>
        {countrySelection}
      </Box>
    </>
  );

  return (
    <Box className='investor-create'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs />
      </Box>
      <Box sx={{ mb: 3 }}>
        {investorTypeForm}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>Investor Information</Typography>
        {investorInformationForm}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>Contact Information</Typography>
        {investorContactInformationForm}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};

export function InvestorDetails() {
  const navigate = useNavigate();
  const countries = require("../utils/countries.json");
  const { investorId } = useParams();

  const { isLoading, isError, data: investorData, error } = useQuery({
    queryKey:[`investors-${investorId}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/investors/${investorId}`);
      return data;
    }, 
    staleTime: Infinity 
  });
  const { data: accountsData  } = useQuery({
    queryKey: [`investor_id-${investorId}-accounts`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/investors/${investorId}/accounts`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!investorData,
    onSuccess: (data) => {
      data?.forEach(
        (account) => {
          account.value =  Number(account.sum_units * account.last_fund_price).toFixed(6)
        }
      );
    }
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(accountsData);
  if (investorData) {
    investorData.country = countries.filter((country) => country.alpha3 === investorData.country)?.at(0)?.name;
  }

  const investorDetailTable = MyVerticalTable(investorData);

  const investorAccountsColumns = [
    { 
      field: 'code', 
      headerName: 'Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'name', 
      headerName: 'Name',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'portfolio_code', 
      headerName: 'Portfolio Code',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'sum_units', 
      headerName: 'Sum Units',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'last_fund_price', 
      headerName: 'Last Fund Price',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    { 
      field: 'value', 
      headerName: 'Value',
      minWidth: 120,
      width: 200,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Orders',
      width: 70,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <IconButton
            label='viewInvestorAccountOrder'
            onClick={() => navigate(`/orders/?investor_account_code=${params.row.code}`)}
          >
            <ViewListIcon />
          </IconButton>
        ];
      },
    }
  ]
  
  const accountsTable = (
    <>
      <DataGrid 
        columns={investorAccountsColumns}
        rows={accountsData ? accountsData : []}
        autoHeight={true}
        loading={isLoading}
        density='compact'
        onRowClick={(params) => navigate(`/investors/${investorId}/accounts/${params.row.code}`)}
        components={{
          LoadingOverlay: LinearProgress,
          Footer: GavFooter,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          footer: { 
            gav: accountsData?.reduce((sum, row) => (sum + Number(row.value)), 0).toFixed(6),
            numRow: accountsData?.length,
          },
          noRowsOverlay: { errorMessage: isError ? stringifyError(error) : null },
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />
      {popperElement}
    </>
  );
  return (
    <Box className='investor-details'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs mapper={{ 1:investorData?.investor_full_name }} />
      </Box>
      <Box>
        <Box sx={{ mb:3 }}>
          {investorDetailTable}
        </Box>
        <Grid container justifyContent='space-between' sx={{ mb:1 }}>
          <Typography variant='h6' sx={{ mb:1 }}>Investor Accounts</Typography>
          <Button
            onClick={() => navigate(`/investors/${investorId}/accounts/create/`, { state: { fullName: investorData?.investor_full_name } })}
            startIcon={<AddIcon />}
          >
            Add account
          </Button>
        </Grid>
        <Box>
          {accountsTable}
        </Box>
      </Box>
    </Box>
  )
};

export function InvestorAccount() {
  
  const navigate = useNavigate();
  const { investorId, investorAccountCode } = useParams();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`investor-account-${investorAccountCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/investor_accounts/${investorAccountCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data:investorOrdersData } = useQuery({ 
    queryKey: [`orders-investor_id${investorId}-investor_account_code${investorAccountCode}-${page}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/orders', {
          params: {
            investor_id:investorId, 
            investor_account_code:investorAccountCode,
            offset: page*pageSize, 
            limit: pageSize
          }
        });
      return data;
  }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  investorOrdersData?.forEach((order) => {
    if (order.latest_status) {
      order.latest_status_datetime = order.latest_status.datetime;
      order.latest_status_ = order.latest_status.status;
    }
  });

  const investorAccountDetail = isLoading ? {} : isError ? stringifyError(error) : data
  investorAccountDetail.value = formatNumber(investorAccountDetail?.sum_units * investorAccountDetail?.last_fund_price);

  const investorAccountDetailTable = MyVerticalTable(investorAccountDetail);

  const createOrderButton = (
    <Button
      onClick={() => navigate(`/orders/create/?investor_account_code=${investorAccountCode}`)}
      startIcon={<AddIcon />}
      size='small'
    >
      Create Order
    </Button>
  )

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(investorOrdersData);

  const table = (
    <>
      <DataGrid 
        columns={ordersColumns}
        rows={investorOrdersData ? investorOrdersData : []}
        loading={isLoading}
        pageSize={pageSize}
        rowCount={100}
        onPageChange={(newPage) => setPage(newPage)}
        onRowClick={(params) => navigate(`/orders/${params.id}`)}
        page={page}
        rowsPerPageOptions={[pageSize]}
        autoHeight
        pagination
        paginationMode="server"
        density='compact'
        components={{
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopperOpen,
            onMouseLeave: handlePopperClose,
          },
        }}
      />
      {popperElement}
    </>
  )
  return (
    <Box className='investor-account'>
      <Box sx={{ mb: 2 }}>
        <MyBreadcrumbs ignoreParents={['accounts']} mapper={{ 1:data?.investor_full_name }} />
      </Box>
      <Box sx={{ mb: 3 }}>
        {investorAccountDetailTable}
      </Box>
      <Box>
        <Grid container justifyContent='space-between' sx={{ mb:1 }}>
          <Typography variant='h6'>Orders</Typography>
          {createOrderButton}
        </Grid>
        <Box>
          {table}
        </Box>
      </Box>
    </Box>
  )
};

export function InvestorAccountCreate() {
  
  const { investorId } = useParams();
  const { state } = useLocation();
  const { fullName } = state; // Read values passed on state

  const [ formValues, setFormValues ] = useState({
    "investor_id": investorId,
    "fund_code": "",
    "code": "",
    "name": null,
    "remarks": null,
  });
  const [open, setOpen] = useState(false);
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { 
    isLoading: isLoadingFunds, 
    isError: isErrorFunds, 
    data: fundsData  
  } = useQuery({
    queryKey: ['funds'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/funds', {params: {fund_status: 'active' }});
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const fundsOptions = isErrorFunds || isLoadingFunds ? [] : fundsData;

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const { remarks, ...payload } = formValues;
      payload.details = { remarks };
      const response = await axiosFundOps.post(`/investor_accounts/create`, payload);
      enqueueSnackbar('Account created. Redirect to investor account detail page.', { variant: 'success' })
      await delay(500);
      navigate(`/investors/${investorId}/accounts/${response.data.code}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create an investor account from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const formInput = (
    <>
      <Box sx={{ display:'flex' }}>
        <TextField
          name="investor"
          label="Investor"
          value={fullName}
          disabled
          fullWidth
          size="small"
          sx={{ pr:2 }}
        />
        <FormControl size='small' sx={{ mb:2, minWidth:'160px' }}>
          <InputLabel>Fund Code</InputLabel>
          <Select
            value={formValues.fund_code}
            name="fund_code"
            label="Fund Code"
            onChange={handleInputChange}
          >
            {
              fundsOptions?.map(fund => 
                <MenuItem key={fund.code} value={fund.code}>{fund.code}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <TextField
          name="code"
          label="Code"
          value={formValues.code}
          fullWidth
          size="small"
          onChange={handleInputChange}
          sx={{ pr: 2 }}
        />
        <TextField
          name="name"
          label="Name"
          value={formValues.name}
          fullWidth
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <TextField
        name="remarks"
        label="Remarks"
        value={formValues.remarks}
        fullWidth
        multiline
        minRows={3}
        onChange={handleInputChange}
      />
    </>
  )

  return (
    <Box className='create-investor-account'>
      <Box sx={{ mb: 2 }}>
        <MyBreadcrumbs ignoreParents={['accounts']} mapper={{ 1:fullName }} />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};